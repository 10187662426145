@charset "UTF-8";
.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: scroll;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
}
body[data-menue-aktiv=true] {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid black;
}

.bild-verhaeltnis {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.bild-verhaeltnis.lazyloaded {
  background-color: white;
  transition: background-color 500ms ease-in;
}

.bild-verhaeltnis img,
.bild-verhaeltnis picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-Bold-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-Bold-WebS.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-BoldItalic-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-BoldItalic-WebS.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-Medium-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-Medium-WebS.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-MediumItalic-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-MediumItalic-WebS.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-Regular-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-Regular-WebS.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "SangBleu";
  src: url("/fonts/SangBleuRepublic-RegularItalic-WebS.woff2") format("woff2"), url("/fonts/SangBleuRepublic-RegularItalic-WebS.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
.buehnen {
  display: flex;
  position: relative;
  justify-content: flex-start;
}
.body[data-buehne-aktiv="2"][data-buehnen-animiert=false] .buehnen {
  display: flex;
  justify-content: flex-end;
}
.buehnen__buehne {
  width: 100%;
}
.buehnen__buehne--1 {
  position: relative;
  transform: translateX(0%);
  width: 100%;
}
.body[data-buehne-aktiv="2"][data-buehnen-animiert=false] .buehnen__buehne--1 {
  left: 0;
  position: fixed;
}
.buehnen__buehne--2 {
  position: fixed;
  right: 0;
  transform: translateX(100%);
  transition: transform 1s;
  top: 0;
}
.body[data-buehne-aktiv="1"] .buehnen__buehne--2 {
  pointer-events: none;
}
.body[data-buehne-aktiv="2"][data-buehnen-animiert=false] .buehnen__buehne--2 {
  position: relative;
}
.buehnen .buehnen__link-buehne-1 {
  height: 100%;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}
.body[data-buehne-aktiv="2"] .buehnen .buehnen__link-buehne-1 {
  display: block;
}

@media (max-width: 767px) {
  .buehnen__buehne--1 {
    padding: 0vw 9.87vw 0vw 3.2vw;
    transition: transform 1s;
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transition: transform 1s;
    transform: translateX(calc((100% - 13.07vw - 29.37vw) / 12 * -10 - 24.03vw - 9.87vw));
  }
  .buehnen__buehne--2 {
    padding: 0vw 9.87vw 0vw 0vw;
    width: calc((100% - 13.07vw - 29.37vw) / 12 * 10 + 24.03vw + 9.87vw);
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--2 {
    transition: transform 1s;
    transform: translateX(0%);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .buehnen__buehne--1 {
    padding: 0vw 10.42vw 0vw 3.13vw;
    transition: transform 1s;
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transform: translateX(calc((100% - 13.54vw - 46.95vw) / 16 * -13 - 39.125vw - 10.42vw));
    transition: transform 1s;
  }
  .buehnen__buehne--2 {
    padding: 0vw 10.42vw 0vw 0vw;
    width: calc((100% - 13.54vw - 46.95vw) / 16 * 14 + 40.69vw + 10.42vw);
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--2 {
    transition: transform 1s;
    transform: translateX(0%);
  }
}
@media (min-width: 1024px) {
  .body[data-buehnen-animiert=true] .buehnen__buehne {
    transition: transform 1s;
  }
  .body[data-buehnen-animiert=false] .buehnen__buehne {
    transition: transform 0.2s;
  }
  .buehnen__buehne--1 {
    padding: 0vw calc((100% - 2.78vw - 25.53vw) / 24 * 4 + 4.44vw + 1.39vw) 0vw calc((100% - 2.78vw - 25.53vw) / 24 * 1 + 1.11vw + 1.39vw);
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--1 {
    transform: translateX(calc((100% - 2.78vw - 25.53vw) / 24 * -18 - 18.87vw - 1.39vw));
  }
  .buehnen__buehne--2 {
    padding-right: calc((100% - 1.39vw - 18.87vw) / 18 * 1 + 1.11vw + 0vw);
    width: calc((100% - 2.78vw - 25.53vw) / 24 * 18 + 18.87vw + 1.39vw);
  }
  .body[data-buehne-aktiv="2"] .buehnen__buehne--2 {
    transform: translateX(0);
  }
  .body[data-buehne-aktiv="2"][data-buehnen-animiert=false][data-buehne-hover="1"] .menue,
  .body[data-buehne-aktiv="2"][data-buehnen-animiert=false][data-buehne-hover="1"] .titel__text,
  .body[data-buehne-aktiv="2"][data-buehnen-animiert=false][data-buehne-hover="1"] .buehnen__buehne {
    animation: buehneAnziehen 0.05s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }
  @keyframes buehneAnziehen {
    0% {
      translate: 0 0;
    }
    100% {
      translate: 0.69vw 0;
    }
  }
  .body[data-buehne-aktiv="2"][data-buehne-hover=false] .menue,
  .body[data-buehne-aktiv="2"][data-buehne-hover=false] .titel__text,
  .body[data-buehne-aktiv="2"][data-buehne-hover=false] .buehnen__buehne {
    animation: buehneLoslassen 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }
  @keyframes buehneLoslassen {
    0% {
      translate: 0.69vw 0;
    }
    100% {
      translate: 0 0;
    }
  }
}
.cursor {
  display: none;
  top: calc(var(--mouse-y) * 100vh - 8px);
  left: calc(var(--mouse-x) * 100vw - 8px);
  position: fixed;
  z-index: 99999999;
  pointer-events: none;
  transition: top 0.2s ease-out, left 0.2s ease-out, width 0.5s, height 0.5s, transform 0.5s, border-radius 0.5s;
  transform: translate(-2px, -2px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
}
body[data-cursor-touch=cursor] .cursor {
  display: block;
}
body[data-linkhover=true][data-cursor=pfeilkreis][data-cursor-touch=cursor][data-buehne-aktiv="1"] .cursor {
  background-image: url("/bausteine/cursor/cursor.svg");
  transform: translate(-1.806vw, -1.806vw);
}
body[data-linkhover=true][data-cursor=pfeilkreis][data-cursor-touch=cursor][data-buehne-aktiv="2"] .cursor {
  background-image: url("/bausteine/cursor/cursor.svg");
  transform: translate(-1.806vw, -1.806vw);
}
body[data-linkhover=true][data-cursor=atkreis][data-cursor-touch=cursor] .cursor {
  background-image: url("/bausteine/cursor/at.svg");
  transform: translate(-1.806vw, -1.806vw);
}
body[data-linkhover=true][data-cursor=telkreis][data-cursor-touch=cursor] .cursor {
  transform: translate(-1.806vw, -1.806vw);
  border-color: transparent;
  transition: border-color 0 linear 0.5s;
}
body[data-linkhover=true][data-cursor=telkreis][data-cursor-touch=cursor] .cursor::after {
  background-image: url("/bausteine/cursor/cursor__sprechblase.svg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
body[data-linkhover=true][data-cursor=pfeilkreis][data-cursor-touch=cursor][data-buehne-aktiv="2"][data-buehne-hover="1"] .cursor {
  background-image: url("/bausteine/cursor/cursor__back.svg");
  transform: translate(-1.806vw, -1.806vw);
}

body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=pfeilkreis] a, body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=atkreis] a, body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] a {
  cursor: none;
}

@media (max-width: 767px) {
  .cursor {
    border: 0.53vw solid black;
    border-radius: 1.07vw;
    height: 2.13vw;
    width: 2.13vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=pfeilkreis] .cursor {
    border-radius: 3.33vw;
    width: 6.67vw;
    height: 6.67vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=atkreis] .cursor {
    border-radius: 3.33vw;
    width: 6.67vw;
    height: 6.67vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor {
    border-radius: 3.33vw;
    width: 6.67vw;
    height: 6.67vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor::after {
    content: "";
    height: 5vw;
    left: -0.53vw;
    position: absolute;
    top: -0.53vw;
    width: calc(100% + 1.07vw);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cursor {
    border: 0.26vw solid black;
    border-radius: 0.52vw;
    width: 1.04vw;
    height: 1.04vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=atkreis] .cursor {
    border-radius: 2.28vw;
    width: 4.56vw;
    height: 4.56vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=pfeilkreis] .cursor {
    border-radius: 2.28vw;
    width: 4.56vw;
    height: 4.56vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor {
    border-radius: 2.28vw;
    width: 4.56vw;
    height: 4.56vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor::after {
    content: "";
    height: 5vw;
    left: -0.26vw;
    position: absolute;
    top: -0.26vw;
    width: calc(100% + 0.52vw);
  }
}
@media (min-width: 1024px) {
  .cursor {
    border: 0.14vw solid black;
    border-radius: 0.42vw;
    height: 0.83vw;
    width: 0.83vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=pfeilkreis] .cursor {
    border-radius: 1.53vw;
    height: 3.06vw;
    width: 3.06vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=atkreis] .cursor {
    border-radius: 1.53vw;
    height: 3.06vw;
    width: 3.06vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor {
    border-radius: 1.53vw;
    height: 1.53vw;
    width: 3.06vw;
  }
  body[data-linkhover=true][data-cursor-touch=cursor][data-cursor=telkreis] .cursor::after {
    content: "";
    height: 5vw;
    left: -0.14vw;
    position: absolute;
    top: -0.14vw;
    width: calc(100% + 0.28vw);
  }
}
.body--404 {
  background-color: black;
}
.body--404 .menue {
  color: white;
}
.body--404 .menue__link {
  color: white;
}
.body--404 .menue__link-sprache {
  color: white;
}
.body--404 .menue__hamburger .hamburger-inner, .body--404 .menue__hamburger .hamburger-inner::after, .body--404 .menue__hamburger .hamburger-inner::before {
  background-color: white;
}
.body--404 .cursor {
  border: 2px solid #fff;
}

.error {
  color: white;
  font-family: "SangBleu";
  font-weight: 500;
}

@media (max-width: 767px) {
  .error {
    margin: 14.67vw 0vw 0vw 0vw;
    margin-left: calc((100% - 0vw - 32.04vw) / 12 * 1 + 2.67vw + 0vw);
    margin-right: calc((100% - 0vw - 29.37vw) / 12 * 0 + 3.204vw + 0vw);
  }
  .error__text {
    margin-bottom: 10.67vw;
    font-size: 6.93vw;
    line-height: 8.53vw;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .error {
    margin: 12.5vw 0vw 0vw 0vw;
  }
  .error__text {
    margin-bottom: 8.33vw;
    font-size: 6.25vw;
    line-height: 7.29vw;
    padding-right: 10.42vw;
  }
}
@media (min-width: 1024px) {
  .error {
    margin: 13.54vw 0vw -5vw 0vw;
    padding-right: calc((100% - 0vw - 18.87vw) / 18 * 1 + 2.22vw + 0vw);
  }
  .error__text {
    font-size: 4.17vw;
    line-height: 4.86vw;
    margin-bottom: 5vw;
  }
}
.kontakt__adresse-titel {
  color: black;
  font-family: "SangBleu";
  font-weight: 500;
}
.kontakt__anreisser {
  color: black;
  font-family: "SangBleu";
  font-weight: 500;
  text-decoration: none;
}
.kontakt__titel, .kontakt__name {
  color: black;
  font-family: "SangBleu";
  font-weight: 700;
}
.kontakt__adresse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.kontakt a, .kontakt a[href^=tel], .kontakt a[href^=mailto] {
  color: black;
}
.kontakt a {
  text-shadow: 0 0.015em #fff, 0 -0.015em #fff, 0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0 0.045em #fff, 0 -0.045em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0 0.06em #fff, 0 -0.06em #fff, 0 0.075em #fff, 0 -0.075em #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0 0.09em #fff, 0 -0.09em #fff;
  background-image: linear-gradient(black, black);
  color: black;
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-repeat: no-repeat;
  transition: background-size 0.5s linear;
  background-position: 0 85%;
}
.kontakt a::selection {
  text-shadow: 0 0.015em #b4d5fe, 0 -0.015em #b4d5fe, 0.03em 0 #b4d5fe, -0.03em 0 #b4d5fe, 0 0.03em #b4d5fe, 0 -0.03em #b4d5fe, 0 0.045em #b4d5fe, 0 -0.045em #b4d5fe, 0.06em 0 #b4d5fe, -0.06em 0 #b4d5fe, 0 0.06em #b4d5fe, 0 -0.06em #b4d5fe, 0 0.075em #b4d5fe, 0 -0.075em #b4d5fe, 0.09em 0 #b4d5fe, -0.09em 0 #b4d5fe, 0 0.09em #b4d5fe, 0 -0.09em #b4d5fe;
  background: #b4d5fe;
}
.kontakt a::-moz-selection {
  text-shadow: 0 0.015em #b4d5fe, 0 -0.015em #b4d5fe, 0.03em 0 #b4d5fe, -0.03em 0 #b4d5fe, 0 0.03em #b4d5fe, 0 -0.03em #b4d5fe, 0 0.045em #b4d5fe, 0 -0.045em #b4d5fe, 0.06em 0 #b4d5fe, -0.06em 0 #b4d5fe, 0 0.06em #b4d5fe, 0 -0.06em #b4d5fe, 0 0.075em #b4d5fe, 0 -0.075em #b4d5fe, 0.09em 0 #b4d5fe, -0.09em 0 #b4d5fe, 0 0.09em #b4d5fe, 0 -0.09em #b4d5fe;
  background: #b4d5fe;
}
.kontakt a *,
.kontakt a *:after, .kontakt a:after,
.kontakt a *:before, .kontakt a:before {
  text-shadow: none;
}
.kontakt a:visited {
  color: black;
}
.kontakt a:hover {
  text-decoration: none;
}
.kontakt .mail-at {
  text-shadow: 0 0.015em #fff, 0 -0.015em #fff, 0.03em 0 #fff, -0.03em 0 #fff, 0 0.03em #fff, 0 -0.03em #fff, 0 0.045em #fff, 0 -0.045em #fff, 0.06em 0 #fff, -0.06em 0 #fff, 0 0.06em #fff, 0 -0.06em #fff, 0 0.075em #fff, 0 -0.075em #fff, 0.09em 0 #fff, -0.09em 0 #fff, 0 0.09em #fff, 0 -0.09em #fff;
  position: relative;
  top: 0.12em;
}

@media (max-width: 767px) {
  .kontakt {
    margin: 14.67vw 0vw 0vw 0vw;
  }
  .kontakt__adresse {
    margin-bottom: 10.67vw;
    font-size: 6.93vw;
    line-height: 8.53vw;
  }
  .kontakt__adresse-plzort {
    margin-bottom: 2.67vw;
  }
  .kontakt__adresse-email, .kontakt__adresse-telefon {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
  .kontakt__team {
    margin-bottom: 21.33vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
    margin-right: calc((100% - 0vw - 26.7vw) / 11 * 1 + 0vw + 0vw);
  }
  .kontakt__team-mitarbeiterinnen {
    margin-top: -5.33vw;
  }
  .kontakt a {
    animation: animationUnterstreichungInaktiv 0.5s ease-in forwards;
  }
  .kontakt a:hover {
    animation: animationUnterstreichungAktiv 0.5s ease-in forwards;
  }
  @keyframes animationUnterstreichungInaktiv {
    0% {
      background-size: 0 0.5px;
    }
    100% {
      background-size: 100% 0.5px;
    }
  }
  @keyframes animationUnterstreichungAktiv {
    0% {
      background-position: 100% 85%;
      background-size: 100% 0.5px;
    }
    100% {
      background-position: 100% 85%;
      background-size: 0 0.5px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .kontakt {
    margin: 12.5vw 0vw 0vw 0vw;
  }
  .kontakt__adresse {
    margin-bottom: 8.33vw;
    font-size: 6.25vw;
    line-height: 7.29vw;
  }
  .kontakt__adresse-plzort {
    margin-bottom: 1.3vw;
  }
  .kontakt__adresse-email, .kontakt__adresse-telefon {
    font-size: 3.65vw;
    line-height: 4.69vw;
  }
  .kontakt__team-container {
    margin-right: calc((100% - 0vw - 43.82vw) / 15 * 1 + 3.13vw + 0vw);
    width: calc((100% - 0vw - 43.82vw) / 15 * 14 + 43.82vw + 0vw);
  }
  .kontakt__team {
    margin-bottom: 12.5vw;
    font-size: 1.82vw;
    line-height: 2.73vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc((100% - 0vw - 84.51vw) / 28 * 0 - 1.565vw + 0vw);
  }
  .kontakt__team-mitarbeiterinnen {
    margin-top: -4.69vw;
  }
  .kontakt__titel {
    margin-left: calc((100% - 0vw - 40.69vw) / 14 * 0 + 1.565vw + 0vw);
    margin-right: calc((100% - 0vw - 40.69vw) / 14 * 1 + 0vw + 0vw);
    width: 100%;
  }
  .kontakt a {
    animation: animationUnterstreichungInaktiv 0.5s ease-in forwards;
  }
  .kontakt a:hover {
    animation: animationUnterstreichungAktiv 0.5s ease-in forwards;
  }
  @keyframes animationUnterstreichungInaktiv {
    0% {
      background-size: 0 1px;
    }
    100% {
      background-size: 100% 1px;
    }
  }
  @keyframes animationUnterstreichungAktiv {
    0% {
      background-position: 100% 85%;
      background-size: 100% 1px;
    }
    100% {
      background-position: 100% 85%;
      background-size: 0 1px;
    }
  }
}
@media (min-width: 1024px) {
  .kontakt {
    margin: 13.54vw 0vw 8.54vw 0vw;
  }
  .kontakt__adresse {
    font-size: 4.17vw;
    line-height: 4.86vw;
    margin-bottom: 5vw;
  }
  .kontakt__adresse-plzort {
    margin-bottom: 2.22vw;
  }
  .kontakt__titel {
    margin-left: calc((100% - 0vw - 19.98vw) / 18 * 0.5 + 1.11vw + 0vw);
    margin-right: calc((100% - 0vw - 19.98vw) / 18 * 0.5 + 1.11vw + 0vw);
    width: 100%;
  }
  .kontakt__team {
    font-size: 1.25vw;
    line-height: 1.67vw;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc((100% - 0vw - 19.98vw) / 18 * -0.5 - 1.11vw + 0vw);
    margin-right: calc((100% - 0vw - 19.98vw) / 18 * -0.5 - 1.11vw + 0vw);
  }
  .kontakt__team-mitarbeiterinnen {
    margin-top: -5vw;
  }
  .kontakt a {
    animation: animationUnterstreichungInaktiv 0.5s ease-in forwards;
  }
  .kontakt a:hover {
    animation: animationUnterstreichungAktiv 0.5s ease-in forwards;
  }
  @keyframes animationUnterstreichungInaktiv {
    0% {
      background-size: 0 1.5px;
    }
    100% {
      background-size: 100% 1.5px;
    }
  }
  @keyframes animationUnterstreichungAktiv {
    0% {
      background-position: 100% 85%;
      background-size: 100% 1.5px;
    }
    100% {
      background-position: 100% 85%;
      background-size: 0 1.5px;
    }
  }
}
.logo {
  color: white;
  font-family: "SangBleu";
  font-weight: 500;
  display: flex;
  mix-blend-mode: exclusion;
  z-index: 10;
}
.logo__text {
  width: 100%;
}
.logo__text--1 {
  left: 0;
  position: fixed;
  text-align: end;
  transform: translateX(0);
  transition: transform 1s;
  top: 0;
}
.logo__text--2 {
  left: 0;
  position: fixed;
  transform: translateX(0);
  transition: transform 1s;
  top: 0;
}
.body[data-buehne-aktiv="2"][data-buehnen-animiert=false] .logo__text--2 {
  position: absolute;
}
.body[data-buehne-aktiv="1"][data-buehnen-animiert=true] .logo__text--2 {
  animation: logo 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}
@keyframes logo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .logo {
    position: absolute;
    width: 100%;
  }
  .body[data-menue-aktiv=true] .logo, .body[data-menue-bewegt=true] .logo {
    display: none;
  }
  .logo__text {
    font-size: 4.8vw;
    line-height: 7.2vw;
    word-spacing: -0.27vw;
  }
  .logo__text--1 {
    padding-right: 9.87vw;
  }
  .logo__text--2 {
    padding-left: calc(100% - 9.87vw);
  }
  .body[data-buehne-aktiv="2"] .logo__text--1, .body[data-buehne-aktiv="2"] .logo__text--2 {
    transform: translateX(calc((100% - 13.07vw - 29.37vw) / 12 * -10 - 24.03vw - 9.87vw));
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .logo {
    position: absolute;
    width: 100%;
  }
  .body[data-menue-aktiv=true] .logo, .body[data-menue-bewegt=true] .logo {
    display: none;
  }
  .logo__text {
    font-size: 3.65vw;
    line-height: 5.34vw;
    word-spacing: -0.39vw;
  }
  .logo__text--1 {
    padding-right: calc((100% - 0vw - 59.47vw) / 16 * 1 + 7.825vw + 0vw);
  }
  .logo__text--2 {
    padding-left: calc((100% - 0vw - 59.47vw) / 16 * 15 + 51.645vw + 0vw);
  }
  .body[data-buehne-aktiv="2"] .logo__text--1, .body[data-buehne-aktiv="2"] .logo__text--2 {
    transform: translateX(calc((100% - 13.54vw - 46.95vw) / 16 * -13 - 39.125vw - 10.42vw));
  }
}
@media (min-width: 1024px) {
  .logo {
    position: absolute;
    width: 100%;
  }
  .logo__text {
    font-size: 2.5vw;
    line-height: 3.68vw;
    word-spacing: -0.21vw;
  }
  .logo__text--1 {
    padding-right: calc((100% - 0vw - 27.75vw) / 24 * 4 + 5.55vw + 0vw);
  }
  .logo__text--2 {
    padding-left: calc((100% - 0vw - 27.75vw) / 24 * 20 + 22.2vw + 0vw);
  }
  .body[data-buehne-aktiv="2"] .logo__text--1, .body[data-buehne-aktiv="2"] .logo__text--2 {
    transform: translateX(calc((100% - 2.78vw - 25.53vw) / 24 * -18 - 18.87vw - 1.39vw));
  }
}
.menue {
  z-index: 1000;
}
.menue__link {
  color: black;
  font-family: "SangBleu";
  font-weight: 500;
  text-decoration: none;
  width: 0;
}
.menue__link--aktiv {
  font-family: "SangBleu";
  font-style: italic;
  font-weight: 500;
}
.menue__link-sprachen {
  font-family: "SangBleu";
  font-weight: 500;
}
.menue__link-sprache {
  color: black;
  text-decoration: none;
}
.menue__logo {
  color: black;
  font-family: "SangBleu";
  font-weight: 500;
  display: flex;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
}
.body[data-menue-aktiv=false][data-menue-bewegt=false] .menue__logo {
  display: none;
}
.menue__logo-text {
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  .menue {
    padding: 0vw 0vw 1.6vw 0vw;
    background-color: #cfd1c1;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(var(--vh, 1vh) * 100);
    right: 0;
    top: 0;
    transition: 500ms linear;
    transform: translate(100%);
    width: 100%;
    z-index: 10;
  }
  .body[data-menue-aktiv=true] .menue {
    transform: translate(0);
    transition: 500ms linear;
  }
  .menue__hamburger-container {
    display: flex;
    justify-content: center;
    position: fixed;
    right: 0;
    top: calc(50% - 9.47vw);
    transition: transform 425ms linear;
    width: 9.87vw;
    z-index: 100;
  }
  .body[data-menue-aktiv=true] .menue__hamburger-container {
    transition: transform 425ms linear;
    transition-delay: 0.075s;
    transform: translateX(calc(-100vw + var(--scrollbar) + 9.87vw));
  }
  .menue__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    padding: 3.47vw 3.47vw 3.47vw 3.47vw;
    transform: rotate(90deg);
    transition: transform 500ms linear;
  }
  .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    background-color: black;
    transition: 0s 0.12s linear;
  }
  .menue__hamburger .hamburger-box {
    width: 6.67vw;
    height: calc(4.5px + (2.93vw - 4.5px) / 2 * 2);
    display: inline-block;
    position: relative;
  }
  .menue__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.75px;
  }
  .menue__hamburger .hamburger-inner, .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    width: 6.67vw;
    height: 1.5px;
    background-color: black;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .menue__hamburger .hamburger-inner::before {
    top: calc(((2.93vw - 4.5px) / 2 + 1.5px) * -1);
  }
  .menue__hamburger .hamburger-inner::after {
    bottom: calc(((2.93vw - 4.5px) / 2 + 1.5px) * -1);
  }
  .menue__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .menue__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .menue__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .menue__link {
    font-size: 9.6vw;
    line-height: 12vw;
    padding-left: calc((100% - 0vw - 32.04vw) / 13 * 1 - 1.335vw + 0vw);
  }
  .menue__link-sprachen {
    font-size: 3.73vw;
    line-height: 6.4vw;
    padding-left: calc((100% - 0vw - 32.04vw) / 13 * 1 - 1.335vw + 0vw);
  }
  .body[data-buehne-aktiv="2"] .menue__logo {
    transform: translateX(calc((100% - 13.07vw - 29.37vw) / 12 * -10 - 24.03vw - 9.87vw));
  }
  .body[data-buehne-aktiv="1"][data-menue-aktiv=true] .menue__logo {
    animation: menueLogoAnimationSmartphoneBuehne1In 125ms linear 375ms none;
  }
  .body[data-buehne-aktiv="1"][data-menue-aktiv=false] .menue__logo {
    animation: menueLogoAnimationSmartphoneBuehne1Out 125ms linear none;
  }
  .body[data-buehne-aktiv="2"][data-menue-aktiv=true] .menue__logo {
    animation: menueLogoAnimationSmartphoneBuehne2In 500ms linear none;
  }
  .body[data-buehne-aktiv="2"][data-menue-aktiv=false] .menue__logo {
    animation: menueLogoAnimationSmartphoneBuehne2Out 500ms linear none;
  }
  .body[data-menue-aktiv=true][data-menue-bewegt=false] .menue__logo {
    transform: translateX(-20vw);
  }
  @keyframes menueLogoAnimationSmartphoneBuehne1In {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-20vw);
    }
  }
  @keyframes menueLogoAnimationSmartphoneBuehne1Out {
    0% {
      transform: translateX(-20vw);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes menueLogoAnimationSmartphoneBuehne2In {
    0% {
      transform: translateX(calc((100% - 13.07vw - 29.37vw) / 12 * -10 - 24.03vw - 9.87vw));
    }
    100% {
      transform: translateX(-20vw);
    }
  }
  @keyframes menueLogoAnimationSmartphoneBuehne2Out {
    0% {
      transform: translateX(-20vw);
    }
    100% {
      transform: translateX(calc((100% - 13.07vw - 29.37vw) / 12 * -10 - 24.03vw - 9.87vw));
    }
  }
  .menue__logo-text {
    font-size: 4.8vw;
    line-height: 7.2vw;
    word-spacing: -0.27vw;
  }
  .menue__logo-text--1 {
    padding-right: 9.87vw;
    text-align: end;
    width: 100%;
  }
  .menue__logo-text--2 {
    width: 100%;
    padding-left: calc(100% - 9.87vw);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .menue {
    padding: 0vw 0vw 3.78vw 0vw;
    background-color: #cfd1c1;
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: flex-end;
    position: fixed;
    right: 0;
    top: 0;
    transition: 500ms linear;
    transform: translate(100%);
    width: 100%;
    z-index: 10;
  }
  .body[data-menue-aktiv=true] .menue {
    transform: translate(0);
    transition: 500ms linear;
  }
  .menue__hamburger-container {
    display: flex;
    justify-content: center;
    position: fixed;
    right: 0;
    top: calc(50% - 6.84vw);
    transition: transform 425ms linear;
    width: 9.11vw;
    z-index: 300;
  }
  .body[data-menue-aktiv=true] .menue__hamburger-container {
    transition: transform 425ms linear;
    transition-delay: 0.075s;
    transform: translateX(calc(-100vw + var(--scrollbar) + 9.11vw));
  }
  .menue__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    padding: 4.23vw 1.69vw 4.23vw 1.69vw;
    transform: rotate(90deg);
    transition: transform 500ms linear;
  }
  .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    background-color: black;
    transition: 0s 0.12s linear;
  }
  .menue__hamburger .hamburger-box {
    width: 3.91vw;
    height: calc(4.5px + (1.95vw - 4.5px) / 2 * 2);
    display: inline-block;
    position: relative;
  }
  .menue__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.75px;
  }
  .menue__hamburger .hamburger-inner, .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    width: 3.91vw;
    height: 1.5px;
    background-color: black;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .menue__hamburger .hamburger-inner::before, .menue__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .menue__hamburger .hamburger-inner::before {
    top: calc(((1.95vw - 4.5px) / 2 + 1.5px) * -1);
  }
  .menue__hamburger .hamburger-inner::after {
    bottom: calc(((1.95vw - 4.5px) / 2 + 1.5px) * -1);
  }
  .menue__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .menue__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .menue__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .menue__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .menue__link {
    font-size: 6.25vw;
    line-height: 7.29vw;
    padding-left: calc((100% - 0vw - 46.95vw) / 16 * 1 - 1.565vw + 0vw);
  }
  .menue__link-sprachen {
    font-size: 1.82vw;
    line-height: 3.13vw;
    padding-left: calc((100% - 0vw - 46.95vw) / 16 * 1 - 1.565vw + 0vw);
  }
  .body[data-buehne-aktiv="2"] .menue__logo {
    transform: translateX(calc((100% - 13.54vw - 46.95vw) / 16 * -13 - 39.125vw - 10.42vw));
  }
  .body[data-buehne-aktiv="1"][data-menue-aktiv=true] .menue__logo {
    animation: menueLogoAnimationTabletBuehne1In 125ms linear 375ms none;
  }
  .body[data-buehne-aktiv="1"][data-menue-aktiv=false] .menue__logo {
    animation: menueLogoAnimationTabletBuehne1Out 125ms linear none;
  }
  .body[data-buehne-aktiv="2"][data-menue-aktiv=true] .menue__logo {
    animation: menueLogoAnimationTabletBuehne2In 500ms linear none;
  }
  .body[data-buehne-aktiv="2"][data-menue-aktiv=false] .menue__logo {
    animation: menueLogoAnimationTabletBuehne2Out 500ms linear none;
  }
  .body[data-menue-aktiv=true][data-menue-bewegt=false] .menue__logo {
    transform: translateX(-12vw);
  }
  @keyframes menueLogoAnimationTabletBuehne1In {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-12vw);
    }
  }
  @keyframes menueLogoAnimationTabletBuehne1Out {
    0% {
      transform: translateX(-12vw);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes menueLogoAnimationTabletBuehne2In {
    0% {
      transform: translateX(calc((100% - 13.54vw - 46.95vw) / 16 * -13 - 39.125vw - 10.42vw));
    }
    100% {
      transform: translateX(-12vw);
    }
  }
  @keyframes menueLogoAnimationTabletBuehne2Out {
    0% {
      transform: translateX(-12vw);
    }
    100% {
      transform: translateX(calc((100% - 13.54vw - 46.95vw) / 16 * -13 - 39.125vw - 10.42vw));
    }
  }
  .menue__logo-text {
    font-size: 3.65vw;
    line-height: 5.34vw;
    word-spacing: -0.39vw;
  }
  .menue__logo-text--1 {
    padding-right: calc((100% - 0vw - 59.47vw) / 16 * 1 + 7.825vw + 0vw);
    text-align: end;
    width: 100%;
  }
  .menue__logo-text--2 {
    width: 100%;
    padding-left: calc((100% - 0vw - 59.47vw) / 16 * 15 + 51.645vw + 0vw);
  }
}
@media (min-width: 1024px) {
  .menue {
    padding: 0vw 1.11vw 1.6vw 0.56vw;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    top: 0;
    transition: 1s;
    transform: translateX(0%);
    width: calc((100% - 2.78vw - 25.53vw) / 24 * 4 + 4.44vw + 1.39vw);
  }
  .body[data-buehne-aktiv="2"] .menue {
    transform: translateX(calc((100% - 1.39vw - 4.44vw) / 4 * -18 - 18.87vw - 1.39vw));
  }
  .menue__link {
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .menue__link:hover {
    font-family: "SangBleu";
    font-style: italic;
    font-weight: 500;
  }
  .menue__link-sprachen {
    font-size: 0.97vw;
    line-height: 1.67vw;
  }
  .menue__link-sprache:hover {
    font-family: "SangBleu";
    font-style: italic;
    font-weight: 500;
  }
  .menue__hamburger {
    display: none;
  }
  .menue__logo {
    display: none;
  }
}
.profil__titel {
  font-family: "SangBleu";
  font-weight: 400;
}
.profil__anreisser {
  font-family: "SangBleu";
  font-weight: 400;
}
.profil__redaktion {
  position: relative;
}
.profil__redaktion::after {
  background-color: #000;
  content: "";
  left: 0;
  position: absolute;
}
.profil__zitat {
  font-family: "SangBleu";
  font-weight: 400;
}
.profil__text {
  font-family: "SangBleu";
  font-weight: 400;
  hyphens: auto;
}
.profil__autor-container {
  font-family: "SangBleu";
}
.profil__autor {
  font-weight: 500;
  position: relative;
}
.profil__autor::after {
  background-color: #000;
  content: "";
  right: 0;
  position: absolute;
}
.profil__rolle {
  font-weight: 400;
}

@media (max-width: 767px) {
  .profil {
    margin: 12vw 0vw 12vw 0vw;
  }
  .profil__titel {
    font-size: 6.4vw;
    line-height: 7.73vw;
  }
  .profil__redaktion-container {
    margin-top: 2.67vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .profil__redaktion {
    padding-left: 2.4vw;
  }
  .profil__redaktion::after {
    top: 0.6vw;
    height: calc(100% - 1.5vw);
    width: 1.5px;
  }
  .profil__anreisser {
    margin: 10.67vw 0vw 5.33vw 0vw;
    font-size: 4.27vw;
    line-height: 6.13vw;
  }
  .profil__bild {
    margin: 5.33vw 0vw 10.67vw 0vw;
    margin-left: calc((100% - 0vw - 24.03vw) / 10 * -1 - 2.67vw + 0vw);
  }
  .profil__bildmitzitat {
    margin: 5.33vw 0vw 5.33vw 0vw;
    margin-left: calc((100% - 0vw - 24.03vw) / 10 * -1 - 2.67vw + 0vw);
  }
  .profil__bildmitzitat--hoch {
    display: flex;
    justify-content: space-between;
  }
  .profil__spalte-small--1 {
    width: calc((100% - 0vw - 26.7vw) / 11 * 4 + 8.01vw + 0vw);
  }
  .profil__spalte-small--2 {
    width: calc((100% - 0vw - 26.7vw) / 11 * 7 + 16.02vw + 0vw);
  }
  .profil__zitat-container--quer {
    margin: 5.33vw 0vw 5.33vw 0vw;
  }
  .profil__zitat {
    font-size: 4.27vw;
    line-height: 6.13vw;
  }
  .profil__autor-container {
    margin-top: 0.8vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
    display: flex;
    flex-wrap: wrap;
  }
  .profil__autor {
    padding-right: 1.87vw;
    margin-right: 1.87vw;
  }
  .profil__autor::after {
    top: 0.2vw;
    height: calc(100% - 0.22vw);
    width: 1.5px;
  }
  .profil__text {
    margin: 5.33vw 0vw 0vw 0vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .profil__text .profil__spalte--1 {
    margin-bottom: 2.67vw;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .profil {
    margin: 12.24vw 0vw 12.24vw 0vw;
  }
  .profil__titel {
    font-size: 6.25vw;
    line-height: 7.29vw;
  }
  .profil__redaktion-container {
    margin-top: 1.04vw;
    font-size: 1.82vw;
    line-height: 2.73vw;
    width: calc((100% - 0vw - 40.69vw) / 14 * 10 + 28.17vw + 0vw);
  }
  .profil__redaktion {
    padding-left: 1.43vw;
  }
  .profil__redaktion::after {
    top: 0.6vw;
    height: calc(100% - 1.5vw);
    width: 1.5px;
  }
  .profil__anreisser {
    margin: 7.81vw 0vw 4.17vw 0vw;
    font-size: 2.73vw;
    line-height: 3.78vw;
  }
  .profil__bild {
    margin: 4.17vw 0vw 7.81vw 0vw;
  }
  .profil__bildmitzitat {
    margin: 4.17vw 0vw 4.17vw 0vw;
  }
  .profil__bildmitzitat--hoch {
    display: flex;
    justify-content: space-between;
  }
  .profil__bildmitzitat--quer {
    width: calc((100% - 0vw - 40.69vw) / 14 * 11 + 31.3vw + 0vw);
  }
  .profil__spalte-small {
    width: calc((100% - 0vw - 40.69vw) / 14 * 7 + 18.78vw + 0vw);
  }
  .profil__zitat-container--quer {
    margin: 4.17vw 0vw 4.17vw 0vw;
  }
  .profil__zitat {
    font-size: 2.73vw;
    line-height: 3.78vw;
  }
  .profil__autor-container {
    margin-top: 0.39vw;
    font-size: 1.56vw;
    line-height: 2.08vw;
    display: flex;
    flex-wrap: wrap;
  }
  .profil__autor {
    padding-right: 0.91vw;
    margin-right: 0.91vw;
  }
  .profil__autor::after {
    top: 0.2vw;
    height: calc(100% - 0.22vw);
    width: 1.5px;
  }
  .profil__text {
    margin: 4.17vw 0vw 7.81vw 0vw;
    font-size: 1.82vw;
    line-height: 2.73vw;
    margin-left: calc((100% - 0vw - 40.69vw) / 14 * 1 + 3.13vw + 0vw);
  }
  .profil__text .profil__spalte--1 {
    margin-bottom: 2.08vw;
  }
}
@media (min-width: 1024px) {
  .profil {
    margin: 13.54vw 0vw 13.54vw 0vw;
  }
  .profil__intro-container {
    display: flex;
  }
  .profil__titel {
    font-size: 4.17vw;
    line-height: 4.86vw;
    width: calc((100% - 0vw - 17.76vw) / 17 * 12 + 12.21vw + 0vw);
    margin-right: calc((100% - 0vw - 17.76vw) / 17 * 0 + 1.11vw + 0vw);
  }
  .profil__redaktion-container {
    font-size: 1.25vw;
    line-height: 1.67vw;
    margin-top: 1.04vw;
    width: calc((100% - 0vw - 17.76vw) / 17 * 5 + 4.44vw + 0vw);
  }
  .profil__redaktion {
    padding-left: 1.04vw;
  }
  .profil__redaktion::after {
    top: 0.3vw;
    height: calc(100% - 0.55vw);
    width: 1.5px;
  }
  .profil__anreisser {
    margin: 5vw 0vw 2.5vw 0vw;
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .profil__bild {
    margin: 2.5vw 0vw 5vw 0vw;
  }
  .profil__bildmitzitat {
    margin: 2.5vw 0vw 2.5vw 0vw;
  }
  .profil__bildmitzitat--hoch {
    display: flex;
    justify-content: space-between;
  }
  .profil__bildmitzitat--quer {
    width: calc((100% - 0vw - 18.87vw) / 18 * 13 + 12.21vw + 0vw);
  }
  .profil__zitat-container--quer {
    margin: 2.5vw 0vw 2.5vw 0vw;
  }
  .profil__zitat {
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .profil__autor-container {
    margin-top: 0.42vw;
    font-size: 0.83vw;
    line-height: 1.11vw;
    display: flex;
    flex-wrap: wrap;
  }
  .profil__autor {
    padding-right: 0.49vw;
    margin-right: 0.49vw;
  }
  .profil__autor::after {
    top: -0.03vw;
    height: calc(100% + 0.1vw);
    width: 1.5px;
  }
  .profil__text {
    margin: 2.5vw 0vw 5vw 0vw;
    font-size: 1.25vw;
    line-height: 1.67vw;
    display: flex;
    justify-content: space-between;
  }
  .profil__spalte {
    width: calc((100% - 0vw - 17.76vw) / 17 * 8 + 7.77vw + 0vw);
  }
  .profil__spalte-small--2 {
    margin-top: -0.55vw;
  }
}
.projekt__anreisser {
  color: black;
  font-family: "SangBleu";
  font-weight: 400;
}
.projekt__anreisser strong {
  font-weight: 400;
}
.projekt__anreisser em {
  font-family: "SangBleu";
  font-style: italic;
}
.projekt__details {
  font-family: "SangBleu";
  font-weight: 400;
  height: 100%;
  position: relative;
}
.projekt__details::after {
  background-color: #000;
  content: "";
  left: 0;
  position: absolute;
}
.projekt__details-titel {
  font-family: "SangBleu";
  font-weight: 700;
}
.projekt__beschreibung {
  font-family: "SangBleu";
  font-weight: 400;
}
.projekt__beschreibung em {
  font-family: "SangBleu";
  font-style: italic;
}
.projekt__beschreibung h3 {
  font-family: "SangBleu";
  font-weight: 700;
}
.projekt__beschreibung h2 {
  font-family: "SangBleu";
  font-weight: 400;
}
.projekt__beschreibung p {
  font-family: "SangBleu";
  font-weight: 400;
}
.projekt__beschreibung-zitat {
  font-family: "SangBleu";
  font-weight: 400;
  margin: 0;
}
.projekt__projektbild-text {
  font-family: "SangBleu";
  font-weight: 500;
  text-align: center;
}

@media (max-width: 767px) {
  .projekt {
    margin-top: 17.07vw;
  }
  .projekt__anreisser {
    font-size: 6.93vw;
    line-height: 8.53vw;
  }
  .projekt__details {
    margin-top: 2.67vw;
    padding-left: 2.4vw;
    width: calc((100% - 0vw - 24.03vw) / 10 * 9 + 21.36vw + 0vw);
  }
  .projekt__details::after {
    top: 0.74vw;
    height: calc(100% - 2.1vw);
    width: 1.5px;
  }
  .projekt__details-titel {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .projekt__details-inhalt {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .projekt__introbild {
    margin: 10.67vw 0vw 5.33vw 0vw;
    margin-left: calc((100% - 0vw - 29.37vw) / 12 * 0 - 2.67vw + 0vw);
  }
  .projekt__beschreibung > *:first-child {
    margin-top: 0;
  }
  .projekt__beschreibung h2 {
    margin: 2.67vw 0vw 2.67vw 0vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
  .projekt__beschreibung h3 {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .projekt__beschreibung p {
    font-size: 3.2vw;
    line-height: 4.27vw;
    margin: 2.67vw 0vw 2.67vw 0vw;
  }
  .projekt__beschreibung p:first-of-type {
    margin-top: 0;
  }
  .projekt .projekt__beschreibung-zitat {
    margin: 1.33vw 0vw 1.33vw 0vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
    margin-left: calc((100% - 0vw - 34.71vw) / 14 * -1 - 4.272vw + 0vw);
  }
  .projekt__projektbilder {
    margin: 8vw 0vw 18.67vw 0vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekt__projektbilder-kompakt {
    margin: 8vw 0vw 18.67vw 0vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekt__projektbild {
    padding-bottom: 2.67vw;
    width: 100%;
    margin: 0;
  }
  .projekt__projektbild--klein {
    width: calc((100% - 0vw - 24.03vw) / 10 * 5 + 10.68vw + 0vw);
  }
  .projekt__projektbild-text {
    margin-top: 0.8vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .projekt {
    margin-top: 12.24vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekt__anreisser {
    font-size: 6.25vw;
    line-height: 7.29vw;
    width: calc((100% - 0vw - 40.69vw) / 14 * 8 + 21.91vw + 0vw);
  }
  .projekt__details {
    padding-left: 1.43vw;
    width: calc((100% - 0vw - 40.69vw) / 14 * 6 + 15.65vw + 0vw);
  }
  .projekt__details::after {
    top: 0.5vw;
    height: calc(100% - 1.4vw);
    width: 1.5px;
  }
  .projekt__details-titel {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
  .projekt__details-inhalt {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
  .projekt__introbild {
    margin: 4.17vw 0vw 4.17vw 0vw;
    width: 100%;
  }
  .projekt__beschreibung {
    margin-left: calc((100% - 0vw - 40.69vw) / 14 * 1 + 3.13vw + 0vw);
  }
  .projekt__beschreibung > *:first-child {
    margin-top: 0;
  }
  .projekt__beschreibung h2 {
    margin: 2.08vw 0vw 2.08vw 0vw;
    font-size: 3.65vw;
    line-height: 4.69vw;
  }
  .projekt__beschreibung h3 {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
  .projekt__beschreibung p {
    font-size: 1.82vw;
    line-height: 2.73vw;
    margin: 2.08vw 0vw 2.08vw 0vw;
  }
  .projekt__beschreibung p:first-of-type {
    margin: 0;
  }
  .projekt__beschreibung .projekt__beschreibung-zitat {
    margin: 1.04vw 0vw 1.04vw 0vw;
    font-size: 3.65vw;
    line-height: 4.69vw;
    margin-left: calc((100% - 0vw - 40.69vw) / 14 * -1 - 3.13vw + 0vw);
    margin-right: calc((100% - 0vw - 40.69vw) / 14 * 2 + 6.26vw + 0vw);
  }
  .projekt__projektbilder, .projekt__projektbilder-kompakt {
    margin: 6.25vw 0vw 8.33vw 0vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .projekt__projektbild {
    padding-bottom: 4.17vw;
    width: 100%;
    margin: 0;
  }
  .projekt__projektbild--klein {
    width: calc((100% - 0vw - 40.69vw) / 14 * 7 + 18.78vw + 0vw);
  }
  .projekt__projektbild-text {
    margin-top: 0.6vw;
    font-size: 1.56vw;
    line-height: 2.08vw;
  }
}
@media (min-width: 1024px) {
  .projekt {
    margin-top: 13.54vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekt__anreisser {
    font-size: 4.17vw;
    line-height: 4.86vw;
    width: calc((100% - 0vw - 17.76vw) / 17 * 12 + 12.21vw + 0vw);
  }
  .projekt__details {
    margin-top: 0.97vw;
    padding-left: 1.04vw;
    width: calc((100% - 0vw - 17.76vw) / 17 * 5 + 4.44vw + 0vw);
  }
  .projekt__details::after {
    top: 0.3vw;
    height: calc(100% - 0.55vw);
    width: 1.5px;
  }
  .projekt__details-titel {
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .projekt__details-inhalt {
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .projekt__introbild {
    margin: 2.5vw 0vw 2.5vw 0vw;
    width: 100%;
  }
  .projekt__beschreibung {
    margin: 1.39vw 0vw 0vw 0vw;
    margin-left: calc((100% - 0vw - 17.76vw) / 17 * 2 + 2.22vw + 0vw);
    margin-right: calc((100% - 0vw - 17.76vw) / 17 * 2 + 2.22vw + 0vw);
  }
  .projekt__beschreibung > *:first-child {
    margin-top: 0;
  }
  .projekt__beschreibung h2 {
    margin: 1.25vw 0vw 1.25vw 0vw;
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .projekt__beschreibung h3 {
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .projekt__beschreibung p {
    font-size: 1.25vw;
    line-height: 1.67vw;
    margin: 1.25vw 0vw 1.25vw 0vw;
  }
  .projekt__beschreibung p:first-of-type {
    margin: 0.35vw 0vw 1.25vw 0vw;
  }
  .projekt__beschreibung .projekt__beschreibung-zitat {
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .projekt__beschreibung-zitat {
    margin: 2.43vw 0vw 2.43vw 0vw;
    margin-left: calc((100% - 0vw - 13.32vw) / 13 * -2 - 2.22vw + 0vw);
  }
  .projekt__projektbilder, .projekt__projektbilder-kompakt {
    margin: 3.75vw 0vw 2.5vw 0vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .projekt__projektbild {
    padding-bottom: 5vw;
    width: 100%;
    margin: 0;
  }
  .projekt__projektbild--klein {
    width: calc((100% - 0vw - 17.76vw) / 17 * 8 + 7.77vw + 0vw);
  }
  .projekt__projektbild-text {
    margin-top: 0.4vw;
    font-size: 0.83vw;
    line-height: 1.11vw;
  }
}
body[data-buehne-aktiv="2"] .projekte.projekt {
  pointer-events: none;
}
.projekte__introbild {
  height: 80vh;
}
.projekte a:link {
  text-decoration: none;
}
.projekte__anreisser {
  color: black;
  font-family: "SangBleu";
  font-weight: 400;
}
.projekte__anreisser strong {
  font-weight: 400;
}
.projekte__anreisser em {
  font-family: "SangBleu";
  font-style: italic;
}
.projekte__details {
  position: relative;
}
.projekte__details::after {
  background-color: #000;
  content: "";
  left: 0;
  position: absolute;
}
.projekte__details-zeile {
  color: black;
}
.projekte__details-zeile--1 {
  font-family: "SangBleu";
  font-weight: 700;
}
.projekte__details-zeile--2 {
  font-family: "SangBleu";
  font-weight: 400;
}
.projekte__zitat {
  font-family: "SangBleu";
  font-weight: 400;
}
.projekte__zitat--2 .projekte__zitat-text {
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.projekte .projekte__zitat-quelle {
  font-family: "SangBleu";
  font-weight: 500;
}

@media (max-width: 767px) {
  .projekte {
    margin-bottom: 10.67vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekte__projekt {
    margin-bottom: 10.67vw;
    width: 100%;
  }
  .projekte__introbild {
    margin-left: -3.2vw;
    height: 62vh;
  }
  .projekte__anreisser--gross {
    margin: 1.33vw 0vw 1.33vw 0vw;
    font-size: 8.53vw;
    line-height: 9.6vw;
    width: calc((100% - 0vw - 29.37vw) / 12 * 11 + 26.7vw + 0vw);
  }
  .projekte__anreisser--klein {
    margin-bottom: 1.33vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
  .projekte__details {
    padding-left: 2.4vw;
    width: calc((100% - 0vw - 29.37vw) / 12 * 11 + 26.7vw + 0vw);
    position: relative;
  }
  .projekte__details::after {
    top: 0.65vw;
    height: calc(100% - 1.6vw);
    width: 1.5px;
  }
  .projekte__details-zeile {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .projekte__details-zeile--1 {
    padding-bottom: 0.8vw;
  }
  .projekte__projekt--klein {
    width: calc((100% - 0vw - 29.37vw) / 12 * 6 + 13.35vw + 0vw);
  }
  .projekte__bild--klein {
    width: 100%;
  }
  .projekte__zitat {
    width: 100%;
  }
  .projekte__zitat--1 {
    margin-bottom: 10.67vw;
    width: calc((100% - 0vw - 29.37vw) / 12 * 11 + 26.7vw + 0vw);
  }
  .projekte__zitat--1 .projekte__zitat-text {
    padding-right: 1.33vw;
    font-size: 8.53vw;
    line-height: 9.6vw;
  }
  .projekte__zitat--1 .projekte__zitat-quelle {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .projekte__zitat--2 {
    margin-bottom: 10.67vw;
    position: relative;
  }
  .projekte__zitat--2 .projekte__zitat-bild {
    margin-left: calc((100% - 0vw - 29.37vw) / 12 * -1 - 5.34vw + 0vw);
    height: 62vh;
  }
  .projekte__zitat--2 .projekte__zitat-texte {
    padding-bottom: 4vw;
    bottom: 0;
    color: white;
    left: 0;
    position: absolute;
    width: calc((100% - 0vw - 29.37vw) / 12 * 11 + 26.7vw + 0vw);
  }
  .projekte__zitat--2 .projekte__zitat-text {
    padding-right: 1.33vw;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
  .projekte__zitat--2 .projekte__zitat-quelle {
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .projekte {
    margin-bottom: 4.69vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekte__projekt {
    margin-bottom: 7.81vw;
    width: 100%;
  }
  .projekte__introbild {
    margin-bottom: 1.04vw;
    margin-left: -3.13vw;
  }
  .projekte__anreisser--gross {
    margin-bottom: 0.65vw;
    font-size: 5.86vw;
    line-height: 7.55vw;
  }
  .projekte__anreisser--klein {
    margin: 0.65vw 0vw 0.65vw 0vw;
    font-size: 3.65vw;
    line-height: 4.69vw;
  }
  .projekte__details {
    padding-left: 1.43vw;
  }
  .projekte__details::after {
    top: 0.6vw;
    height: calc(100% - 1.25vw);
    width: 1.5px;
  }
  .projekte__details-zeile {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
  .projekte__projekt--klein {
    width: calc((100% - 0vw - 46.95vw) / 16 * 8 + 21.91vw + 0vw);
  }
  .projekte__bild--klein {
    width: 100%;
  }
  .projekte__zitat {
    width: 100%;
  }
  .projekte__zitat--1 {
    margin-bottom: 7.81vw;
  }
  .projekte__zitat--1 .projekte__zitat-text {
    padding-right: 0.91vw;
    font-size: 5.86vw;
    line-height: 7.55vw;
  }
  .projekte__zitat--1 .projekte__zitat-quelle {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
  .projekte__zitat--2 {
    margin-bottom: 7.81vw;
    position: relative;
  }
  .projekte__zitat--2 .projekte__zitat-bild {
    margin-left: calc((100% - 0vw - 43.82vw) / 15 * -1 - 6.26vw + 0vw);
    height: 80vh;
  }
  .projekte__zitat--2 .projekte__zitat-texte {
    padding-bottom: 3.26vw;
    bottom: 0;
    color: white;
    left: 0;
    padding-right: calc((100% - 0vw - 46.95vw) / 16 * 1 + 0vw + 0vw);
    position: absolute;
    width: 100%;
  }
  .projekte__zitat--2 .projekte__zitat-text {
    padding-right: 0.91vw;
    font-size: 4.69vw;
    line-height: 5.86vw;
  }
  .projekte__zitat--2 .projekte__zitat-quelle {
    font-size: 1.82vw;
    line-height: 2.73vw;
  }
}
@media (min-width: 1024px) {
  .projekte {
    margin-bottom: 5.21vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekte__projekt {
    width: 100%;
    margin-bottom: 4.17vw;
  }
  .projekte__introbild {
    margin-bottom: 2.08vw;
    margin-left: calc((100% - 0vw - 19.98vw) / 19 * -1 - 1.11vw - 1.39vw);
    overflow: hidden;
  }
  .projekte__anreisser--gross {
    margin-bottom: 1.04vw;
    font-size: 4.17vw;
    line-height: 4.86vw;
    margin-right: calc((100% - 0vw - 19.98vw) / 19 * 1 + 1.11vw + 0vw);
  }
  .projekte__anreisser--klein {
    margin-bottom: 0.69vw;
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .projekte__details {
    padding-left: 1.04vw;
  }
  .projekte__details::after {
    top: 0.3vw;
    height: calc(100% - 0.55vw);
    width: 1.5px;
  }
  .projekte__details-zeile {
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .projekte__projekt--klein {
    width: calc((100% - 0vw - 19.98vw) / 19 * 9 + 8.88vw + 0vw);
  }
  .projekte__projekt:hover .projekte__bild-bild,
  .projekte__projekt:hover .projekte__introbild-bild {
    transform: scale(1.1);
    transition: transform 0.6s ease-out;
  }
  .projekte__projekt:hover .projekte__introbild-bild {
    transform: scale(1.05);
    transition: transform 0.6s ease-out;
  }
  .projekte__projekt .projekte__bild-bild,
  .projekte__projekt .projekte__introbild-bild {
    transition: transform 0.6s ease-out;
    transform: scale(1);
  }
  .projekte__bild {
    margin-bottom: 1.39vw;
    overflow: hidden;
  }
  .projekte__bild--klein {
    width: 100%;
  }
  .projekte__zitat {
    width: 100%;
  }
  .projekte__zitat--1 {
    margin: 0vw 0vw 4.44vw 0vw;
    width: calc((100% - 0vw - 19.98vw) / 19 * 16 + 16.65vw + 0vw);
  }
  .projekte__zitat--1 .projekte__zitat-text {
    padding-right: 1.74vw;
    font-size: 4.17vw;
    line-height: 4.86vw;
  }
  .projekte__zitat--1 .projekte__zitat-quelle {
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .projekte__zitat--2 {
    margin: 0vw 0vw 4.44vw 0vw;
    position: relative;
  }
  .projekte__zitat--2 .projekte__zitat-bild {
    margin-left: calc((100% - 0vw - 19.98vw) / 19 * -1 - 2.22vw + 0vw);
    height: 80vh;
  }
  .projekte__zitat--2 .projekte__zitat-texte {
    width: calc((100% - 0vw - 19.98vw) / 19 * 16 + 16.65vw + 0vw);
    padding-bottom: 4.17vw;
    color: white;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .projekte__zitat--2 .projekte__zitat-text {
    padding-right: 1.39vw;
    font-size: 2.5vw;
    line-height: 3.13vw;
  }
  .projekte__zitat--2 .projekte__zitat-quelle {
    font-size: 1.25vw;
    line-height: 1.46vw;
  }
}
.teammitglied {
  font-family: "SangBleu";
}
.teammitglied__name {
  font-weight: 700;
}
.teammitglied__details {
  font-weight: 400;
}

@media (max-width: 767px) {
  .teammitglied {
    margin-bottom: 5.33vw;
    font-size: 3.2vw;
    line-height: 4.27vw;
  }
  .teammitglied__bild {
    margin-bottom: 0.53vw;
    margin-right: calc((100% - 0vw - 26.7vw) / 11 * 0 + 2.67vw + 0vw);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .teammitglied {
    margin-bottom: 4.17vw;
    font-size: 1.82vw;
    line-height: 2.73vw;
    width: calc((100% - 0vw - 45.385vw) / 14 * 7 + 18.78vw + 0vw);
    margin-left: calc((100% - 0vw - 40.69vw) / 14 * 0 + 1.565vw + 0vw);
    margin-right: calc((100% - 0vw - 40.69vw) / 14 * 0 + 1.565vw + 0vw);
  }
  .teammitglied__bild {
    margin-bottom: 0.39vw;
  }
}
@media (min-width: 1024px) {
  .teammitglied {
    margin-bottom: 5vw;
    width: calc((100% - 0vw - 19.98vw) / 18 * 5 + 4.44vw + 0vw);
    margin-left: calc((100% - 0vw - 19.98vw) / 18 * 0.5 + 1.11vw + 0vw);
    margin-right: calc((100% - 0vw - 19.98vw) / 18 * 0.5 + 1.11vw + 0vw);
  }
  .teammitglied__name {
    margin-bottom: 0.35vw;
    font-size: 1.25vw;
    line-height: 1.67vw;
  }
  .teammitglied__details {
    font-size: 0.97vw;
    line-height: 1.46vw;
  }
  .teammitglied__bild {
    margin-bottom: 0.35vw;
  }
}
.werkliste__projekt {
  position: relative;
  display: flex;
  flex-direction: column;
  color: black;
  text-decoration: none;
}
.werkliste__projekt[href] .werkliste__titel {
  font-style: italic;
}
.werkliste__titel {
  font-family: "SangBleu";
  font-weight: 400;
}
.werkliste__gruppe {
  font-family: "SangBleu";
  font-weight: 500;
}
.werkliste__details {
  font-family: "SangBleu";
  font-weight: 400;
  position: relative;
  height: 100%;
}
.werkliste__inhalt {
  position: relative;
}
.werkliste__inhalt::after {
  background-color: #000;
  content: "";
  left: 0;
  position: absolute;
}

@media (max-width: 767px) {
  .werkliste {
    margin: 14.67vw 0vw 16vw 0vw;
  }
  .werkliste__gruppe {
    font-size: 3.2vw;
    line-height: 3.2vw;
    margin: 10.67vw 0vw 1.07vw 0vw;
  }
  .werkliste__projekt--ohne-link {
    margin-bottom: 5.33vw;
  }
  .werkliste__projekt--mit-link {
    margin-bottom: 10.67vw;
  }
  .werkliste__titel {
    font-size: 6.93vw;
    line-height: 8.53vw;
    margin-bottom: 2.67vw;
  }
  .werkliste__details {
    font-size: 3.2vw;
    line-height: 4.27vw;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .werkliste__inhalt {
    padding-left: 1.87vw;
    width: calc((100% - 0vw - 24.03vw) / 10 * 6.45 + 17.2215vw + 0vw);
  }
  .werkliste__inhalt::after {
    top: 0.7vw;
    height: calc(100% - 1.7vw);
    width: 1.5px;
  }
  .werkliste__bild {
    width: calc((100% - 0vw - 24.03vw) / 10 * 3.1666666667 + 8.455vw + 0vw);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .werkliste {
    margin: 12.5vw 0vw 10.42vw 0vw;
  }
  .werkliste__gruppe {
    font-size: 1.82vw;
    line-height: 1.82vw;
    margin: 9.38vw 0vw 0.65vw 0vw;
  }
  .werkliste__projekt--ohne-link {
    margin-bottom: 2.08vw;
  }
  .werkliste__projekt--mit-link {
    margin-bottom: 9.38vw;
  }
  .werkliste__titel {
    font-size: 6.25vw;
    line-height: 7.29vw;
    margin-bottom: 0.65vw;
  }
  .werkliste__details {
    font-size: 1.82vw;
    line-height: 2.73vw;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .werkliste__inhalt {
    padding-left: calc((100% - 0vw - 84.51vw) / 28 * 1.5 + 1.565vw + 0vw);
    width: calc((100% - 0vw - 40.69vw) / 14 * 5 + 12.52vw + 0vw);
  }
  .werkliste__inhalt::after {
    top: 0.535vw;
    height: calc(100% - 1.2vw);
    width: 1.5px;
  }
  .werkliste__bild {
    width: calc((100% - 0vw - 40.69vw) / 14 * 5 + 12.52vw + 0vw);
  }
}
@media (min-width: 1024px) {
  .werkliste {
    margin: 13.54vw 0vw 19.03vw 0vw;
  }
  .werkliste__gruppe {
    font-size: 1.25vw;
    line-height: 1.25vw;
    margin: 5vw 0vw 0.35vw 0vw;
  }
  .werkliste__projekt {
    padding-bottom: 4.17vw;
    padding-right: calc((100% - 0vw - 17.76vw) / 17 * 4 + 4.44vw + 0vw);
  }
  .werkliste__projekt:hover .werkliste__details {
    opacity: 1;
    transition: 0.3s;
    transition-timing-function: ease-in;
  }
  .werkliste__titel {
    font-size: 4.17vw;
    line-height: 4.86vw;
  }
  .werkliste__details {
    margin-right: calc((100% - 0vw - 17.76vw) / 17 * -1 - 1.11vw + 0vw);
    font-size: 1.25vw;
    line-height: 1.67vw;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease-out;
    width: calc((100% - 0vw - 17.76vw) / 17 * 5 + 4.44vw + 0vw);
  }
  .werkliste__inhalt {
    padding-left: 1.04vw;
  }
  .werkliste__inhalt::after {
    top: 0.3vw;
    height: calc(100% - 0.55vw);
    width: 1.5px;
  }
  .werkliste__bild {
    width: 100%;
    margin-top: 0.9vw;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

body[data-menue-aktiv=true] {
  position: fixed;
  width: 100%;
}

.seite {
  position: relative;
}

@media (max-width: 767px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
}

@media (max-width: 767px) {
  .main {
    padding: 24vw 0vw 0vw 0vw;
  }
}
@media (min-width: 1024px) {
  .main {
    padding: 0vw 0vw 0vw 0vw;
  }
}