$menueAnimationsdauer: $menueAnimationsdauer * 1ms;
// Der AnimationDelayFaktor ist die prozentuale Breite, die das Logo bereits im Screen steht
// Er wird nur für Bühne 1 benötigt
$menueLogoAnimationDelayFaktor: 0.25;
$menueLogoAnimationDelay: (1 - $menueLogoAnimationDelayFaktor) * $menueAnimationsdauer;

.menue {
    z-index: 1000;

    &__link {
        color: black;
        font-family: "SangBleu";
        font-weight: 500;
        text-decoration: none;
        width: 0;
    }

    &__link {

        &--aktiv {
            font-family: "SangBleu";
            font-style: italic;
            font-weight: 500;
        }
    }

    &__link-sprachen {
        font-family: "SangBleu";
        font-weight: 500;
    }

    &__link-sprache {
        color: black;
        text-decoration: none;
    }

    &__logo {
        color: black;
        font-family: "SangBleu";
        font-weight: 500;
        display: flex;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 20;
        width: 100%;

        .body[data-menue-aktiv="false"][data-menue-bewegt="false"] & {
            display: none;
        }
    }

    &__logo-text {
        position: absolute;
        left: 0;
        top: 0;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .menue {
        @include abstand($layout, padding, alle, 0, 0, 6, 0);
        background-color: map-get($farben, gruenSheen);
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: calc(var(--vh, 1vh) * 100);
        right: 0;
        top: 0;
        transition: $menueAnimationsdauer linear;
        transform: translate(100%);
        width: 100%;
        z-index: 10;

        .body[data-menue-aktiv="true"] & {
            transform: translate(0);
            transition: $menueAnimationsdauer linear;
        }

        &__hamburger-container {
            display: flex;
            justify-content: center;
            position: fixed;
            right: 0;
            // Minus (Hamburgerhöhe + padding links/rechts) / 2
            // Weil der Hamburger um seine Mitte gedreht wird
            // 10 zum Ausgleich
            top: calc(50% - groesse($layout, calc((25 + 13 + 13) / 2 + 10)));
            transition: transform calc($menueAnimationsdauer - 0.075s) linear;
            width: calc(groesse($layout, 37));
            z-index: 100;

            .body[data-menue-aktiv="true"] & {
                transition: transform calc($menueAnimationsdauer - 0.075s) linear;
                transition-delay: 0.075s;
                transform: translateX(calc(-100vw + var(--scrollbar) + groesse($layout, 37)));
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 25);
            $hamburger-hoehe: groesse($layout, 11);
            $hamburger-strichstaerke: 1.5px;
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, padding, alle, calc((37 - 11) / 2), 13, calc((37 - 11) / 2), 13);
            transform: rotate(90deg);
            transition: transform $menueAnimationsdauer linear;
        }
        

        &__link {
            @include schriftart($layout, 36, 45);
            padding-left: spalten($layout, 1, 13, 0, -0.5);
        }

        &__link-sprachen {
            @include schriftart($layout, 14, 24);
            padding-left: spalten($layout, 1, 13, 0, -0.5);
        }
        
        &__logo {
            $logoVerschiebung: -20vw;

            .body[data-buehne-aktiv="2"] & {
                // Kopiert von _buehnen.scss
                transform: translateX(spalten($layout, 10, 12, 0, 0, 12 + 37, 37, true));
            }

            .body[data-buehne-aktiv="1"][data-menue-aktiv="true"] & {
                animation: menueLogoAnimationSmartphoneBuehne1In ($menueAnimationsdauer - $menueLogoAnimationDelay) linear $menueLogoAnimationDelay none;
            }
            
            .body[data-buehne-aktiv="1"][data-menue-aktiv="false"] & {
                animation: menueLogoAnimationSmartphoneBuehne1Out ($menueAnimationsdauer - $menueLogoAnimationDelay) linear none;
            }
            
            .body[data-buehne-aktiv="2"][data-menue-aktiv="true"] & {
                animation: menueLogoAnimationSmartphoneBuehne2In $menueAnimationsdauer linear none;
            }
            
            .body[data-buehne-aktiv="2"][data-menue-aktiv="false"] & {
                animation: menueLogoAnimationSmartphoneBuehne2Out $menueAnimationsdauer linear none;
            }
            
            // Nach der Keyframeanimation wird die Position des Logos hiermit definiert.
            // Die Verwendung von animation-fill: forwards führt zu Positionierungsfehlern:
            // Bei Viewportänderungen wird die Position falsch berechnet.
            .body[data-menue-aktiv="true"][data-menue-bewegt="false"] & {
                transform: translateX($logoVerschiebung);
            }

            @keyframes menueLogoAnimationSmartphoneBuehne1In {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX($logoVerschiebung);
                }
            }
            
            @keyframes menueLogoAnimationSmartphoneBuehne1Out {
                0% {
                    transform: translateX($logoVerschiebung);
                }
                100% {
                    transform: translateX(0);
                }
            }
            
            @keyframes menueLogoAnimationSmartphoneBuehne2In {
                0% {
                    transform: translateX(spalten($layout, 10, 12, 0, 0, 12 + 37, 37, true));
                }
                100% {
                    // Wie in AnimationBuehne1
                    transform: translateX($logoVerschiebung);
                }
            }
            
            @keyframes menueLogoAnimationSmartphoneBuehne2Out {
                0% {
                    // Wie in AnimationBuehne1
                    transform: translateX($logoVerschiebung);
                }
                100% {
                    transform: translateX(spalten($layout, 10, 12, 0, 0, 12 + 37, 37, true));
                }
            }
        }

        &__logo-text {
            @include schriftart($layout, 18, 27);
            word-spacing: groesse($layout, -1);
            
            &--1 {
                padding-right: groesse($layout, 37);
                text-align: end;
                width: 100%;
            }
            
            
            &--2 {
                width: 100%;
                padding-left: calc(100% - groesse($layout, 37));
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .menue {
        @include abstand($layout, padding, alle, 0, 0, 29, 0);
        background-color: map-get($farben, gruenSheen);
        display: flex;
        flex-direction: column;
        height: calc(var(--vh, 1vh) * 100);
        justify-content: flex-end;
        position: fixed;
        right: 0;
        top: 0;
        transition: $menueAnimationsdauer linear;
        transform: translate(100%);
        width: 100%;
        z-index: 10;

        .body[data-menue-aktiv="true"] & {
            transform: translate(0);
            transition: $menueAnimationsdauer linear;
        }

        &__hamburger-container {
            display: flex;
            justify-content: center;
            position: fixed;
            right: 0;
            // Minus (Hamburgerhöhe + padding links/rechts) / 2
            // Weil der Hamburger um seine Mitte gedreht wird
            // 10 zum Ausgleich
            top: calc(50% - groesse($layout, calc((15 + 20 + 20) / 2 + 25)));
            transition: transform calc($menueAnimationsdauer - 0.075s) linear;
            width: calc(groesse($layout, 20 + 30 + 20));
            z-index: 300;
            
            .body[data-menue-aktiv="true"] & {
                transition: transform calc($menueAnimationsdauer - 0.075s) linear;
                transition-delay: 0.075s;
                transform: translateX(calc(-100vw + var(--scrollbar) + groesse($layout, 20 + 30 + 20)));
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 30);
            $hamburger-hoehe: groesse($layout, 15);
            $hamburger-strichstaerke: 1.5px;
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, padding, alle, calc((80 - 15) / 2), 13, calc((80 - 15) / 2), 13);
            transform: rotate(90deg);
            transition: transform $menueAnimationsdauer linear;
        }

        &__link {
            @include schriftart($layout, 48, 56);
            padding-left: spalten($layout, 1, 16, 0, -0.5);
        }

        &__link-sprachen {
            @include schriftart($layout, 14, 24);
            padding-left: spalten($layout, 1, 16, 0, -0.5);
        }

        &__logo {
            $logoVerschiebung: -12vw;

            .body[data-buehne-aktiv="2"] & {
                // Kopiert von _buehnen.scss
                transform: translateX(spalten($layout, 13, 16, 0, 0.5, 80 + 24, 80, true));
            }
            
            // Exakt wie in Smartphone, bloss angepasste spalten-Werte
            .body[data-buehne-aktiv="1"][data-menue-aktiv="true"] & {
                animation: menueLogoAnimationTabletBuehne1In ($menueAnimationsdauer - $menueLogoAnimationDelay) linear $menueLogoAnimationDelay none;
            }
            
            .body[data-buehne-aktiv="1"][data-menue-aktiv="false"] & {
                animation: menueLogoAnimationTabletBuehne1Out ($menueAnimationsdauer - $menueLogoAnimationDelay) linear none;
            }
            
            .body[data-buehne-aktiv="2"][data-menue-aktiv="true"] & {
                animation: menueLogoAnimationTabletBuehne2In $menueAnimationsdauer linear none;
            }
            
            .body[data-buehne-aktiv="2"][data-menue-aktiv="false"] & {
                animation: menueLogoAnimationTabletBuehne2Out $menueAnimationsdauer linear none;
            }
            
            // Erklärung siehe Smartphone
            .body[data-menue-aktiv="true"][data-menue-bewegt="false"] & {
                transform: translateX($logoVerschiebung);
            }

            @keyframes menueLogoAnimationTabletBuehne1In {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX($logoVerschiebung);
                }
            }
            
            @keyframes menueLogoAnimationTabletBuehne1Out {
                0% {
                    transform: translateX($logoVerschiebung);
                }
                100% {
                    transform: translateX(0);
                }
            }
            
            @keyframes menueLogoAnimationTabletBuehne2In {
                0% {
                    transform: translateX(spalten($layout, 13, 16, 0, 0.5, 80 + 24, 80, true));
                }
                100% {
                    // Wie in AnimationBuehne1
                    transform: translateX($logoVerschiebung);
                }
            }
            
            @keyframes menueLogoAnimationTabletBuehne2Out {
                0% {
                    // Wie in AnimationBuehne1
                    transform: translateX($logoVerschiebung);
                }
                100% {
                    transform: translateX(spalten($layout, 13, 16, 0, 0.5, 80 + 24, 80, true));
                }
            }
        }
        
        &__logo-text {
            @include schriftart($layout, 28, 41);
            word-spacing: groesse($layout, -3);
            
            &--1 {
                padding-right: spalten($layout, 1, 16, 4, 2.5);
                text-align: end;
                width: 100%;
            }

            &--2 {
                width: 100%;
                padding-left: spalten($layout, 15, 16, 4, 2.5);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        @include abstand($layout, padding, alle, 0, 16, 23, 8);
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: flex-end;
        position: fixed;
        right: 0;
        top: 0;
        transition: 1.0s;
        transform: translateX(0%);
        width: spalten($layout, 4, 24, 0, 1, 20 + 20, 20);
        
        .body[data-buehne-aktiv="2"] & {
            transform: translateX(spalten($layout, 18, 4, 1, 0, 20, 20, true));
        }

        &__link {
            @include schriftart($layout, 36, 45);

            &:hover {
                font-family: "SangBleu";
                font-style: italic;
                font-weight: 500;
            }
        }

        &__link-sprachen {
            @include schriftart($layout, 14, 24);
        }

        &__link-sprache {

            &:hover {
                font-family: "SangBleu";
                font-style: italic;
                font-weight: 500;
            }
        }

        &__hamburger {
            display: none;
        }

        &__logo {
            display: none;
        }
    }
}