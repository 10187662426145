// sassy link undelines
@mixin text-underline-crop($background) {
    // Vertikale Aussparung eingerückt zur besseren Lesbarkeit
    // Es braucht etwas vertikale Aussparung, damit das @ und Linie nicht kollidieren;
    // mit zuviel vertikaler Aussparung würde die Linie angeknabbert (weisse Flecken)
    text-shadow:
            0 .015em $background,
            0 -.015em $background,
        .03em 0 $background,
       -.03em 0 $background,
            0 .03em $background,
            0 -.03em $background,
            0 .045em $background,
            0 -.045em $background,
        .06em 0 $background,
       -.06em 0 $background,
            0 .06em $background,
            0 -.06em $background,
            0 .075em $background,
            0 -.075em $background,
        .09em 0 $background,
       -.09em 0 $background,
            0 .09em $background,
            0 -.09em $background;
}

@mixin text-underline($color-bg, $color-text) {
    background-image: linear-gradient($color-text, $color-text);
}

@mixin text-selection($selection) {
    &::selection {
        @include text-underline-crop($selection);

        background: $selection;
    }

    &::-moz-selection {
        @include text-underline-crop($selection);

        background: $selection;
    }
}

@mixin link-underline($background, $text, $selection) {
    @include text-underline-crop($background);
    @include text-underline($background, $text);
    @include text-selection($selection);

    color: $text;
    text-decoration: none;

    *,
    *:after,
    &:after,
    *:before,
    &:before {
        text-shadow: none;
    }

    &:visited {
        color: $text;
    }
}

@mixin animation($strichdicke) {
    a {
        animation: animationUnterstreichungInaktiv 0.5s ease-in forwards;
    }
    a:hover {
        animation: animationUnterstreichungAktiv 0.5s ease-in forwards;
    }

    @keyframes animationUnterstreichungInaktiv {
        0% {
            background-size: 0 $strichdicke;
        }
        100% {
            background-size: 100% $strichdicke;
        }
    }
    
    @keyframes animationUnterstreichungAktiv {
        0% {
            background-position: 100% 85%;
            background-size: 100% $strichdicke;
        }
        100% {
            background-position: 100% 85%;
            background-size: 0 $strichdicke;
        }
    }
}

$color-text: black;
$color-background: #fff;
$color-selection: #b4d5fe;

.kontakt {

    &__adresse-titel {
        color: black;
        font-family: "SangBleu";
        font-weight: 500;
    }

    &__anreisser {
        color: black;
        font-family: "SangBleu";
        font-weight: 500;
        text-decoration: none;
    }

    &__titel,
    &__name {
        color: black;
        font-family: "SangBleu";
        font-weight: 700;
    }


    &__adresse {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    a, a[href^=tel], a[href^=mailto] {
        color: black;
    }

    a {
        @include link-underline($color-background, $color-text, $color-selection);
        background-image: linear-gradient(black, black);
        background-repeat: no-repeat;
        transition: background-size 0.5s linear;
        background-position: 0 85%;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    .mail-at {
        @include text-underline-crop($color-background);
        position: relative;
        top: 0.12em;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontakt {
        @include abstand($layout, margin, alle, 55, 0, 0, 0);

        &__adresse {
            @include abstand($layout, margin, bottom, 40);
            @include schriftart($layout, 26, 32);
        }


        &__adresse-plzort {
            @include abstand($layout, margin, bottom, 10);
        }

        &__adresse-email, &__adresse-telefon {
            @include schriftart($layout, 18, 24);
        }

        &__team {
            @include abstand($layout, margin, bottom, 80);
            @include schriftart($layout, 12, 16);
            margin-right: spalten($layout, 1, 11, 0, 0);
        }

        &__team-mitarbeiterinnen {
            // -20 teammitglied
            @include abstand($layout, margin, top, -20);
        }

        @include animation(0.5px);
    }

}

@include mediaquery(tablet) {

    $layout: tablet;

    .kontakt {
        @include abstand($layout, margin, alle, 96, 0, 0, 0);

        &__adresse {
            @include abstand($layout, margin, bottom, 64);
            @include schriftart($layout, 48, 56);
        }

        &__adresse-plzort {
            @include abstand($layout, margin, bottom, 10);
        }

        &__adresse-email, &__adresse-telefon {
            @include schriftart($layout, 28, 36);
        }

        &__team-container {
            margin-right: spalten($layout, 1, 15, 0, 1);
            width: spalten($layout, 14, 15, 0, 1);
        }

        &__team {
            @include abstand($layout, margin, bottom, 96);
            @include schriftart($layout, 14, 21);
            display: flex;
            flex-wrap: wrap;
            margin-left: spalten($layout, 0, 28, 0, 0.5);
        }

        &__team-mitarbeiterinnen {
            // -96 == team
            @include abstand($layout, margin, top, - (96 - 60));
        }

        &__titel {
            margin-left: spalten($layout, 0, 14, 0, 1.5);
            margin-right: spalten($layout, 1, 14, 0, 0);
            width: 100%;
        }

        @include animation(1.0px);
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .kontakt {
        @include abstand($layout, margin, alle, 195, 0, (195 - 72), 0);

        &__adresse {
            @include schriftart($layout, 60, 70);
            @include abstand($layout, margin, bottom, 72);
        }

        &__adresse-plzort {
            @include abstand($layout, margin, bottom, 32);
        }

        &__titel {
            margin-left: spalten($layout, 0.5, 18, 1, 1.5);
            margin-right: spalten($layout, 0.5, 18, 1, 1.5);
            width: 100%;
        }

        &__team {
            @include schriftart($layout, 18, 24);
            display: flex;
            flex-wrap: wrap;
            margin-left: spalten($layout, -0.5, 18, 1, 0.5);
            margin-right: spalten($layout, -0.5, 18, 1, 0.5);
        }

        &__team-mitarbeiterinnen {
            // 72 == margin-bottom teammitglied
            @include abstand($layout, margin, top, - 72);
        }

        @include animation(1.5px);
    }
}