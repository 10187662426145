.projekte {

    &.projekt {
        body[data-buehne-aktiv="2"] & {
            pointer-events: none;
        }
    }

    &__introbild {
        height: 80vh;
    }

    & a:link {
        text-decoration: none;
    }

    &__anreisser {
        color: black;
        font-family: "SangBleu";
        font-weight: 400;

        // Auch wenn kein Button im Redactor field für bold steht,
        // kann man per Shortcut bold setzen.
        // Zusätzlich Shortcuts im Redactor field deaktiviert.
        & strong {
            font-weight: 400;
        }

        & em {
            font-family: "SangBleu";
            font-style: italic;
        }
    }

    &__details {
        position: relative;

        &::after {
            background-color: #000;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    &__details-zeile {
        color: black;

        &--1 {
            font-family: "SangBleu";
            font-weight: 700;
        }

        &--2 {
            font-family: "SangBleu";
            font-weight: 400;
        }
    }

    &__zitat {
        font-family: "SangBleu";
        font-weight: 400;

        &--2 {
            & .projekte__zitat-text {
                text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
            }
        }
    }

    .projekte__zitat-quelle {
        font-family: "SangBleu";
        font-weight: 500;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekte {
        @include abstand($layout, margin, bottom, (80 - 40));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__projekt {
            @include abstand($layout, margin, bottom, 40);
            width: 100%;
        }

        &__introbild {
            margin-left: groesse($layout, -12);
            height: 62vh;
        }

        &__anreisser {

            &--gross {
                @include abstand($layout, margin, alle, 5, 0, 5, 0);
                @include schriftart($layout, 32, 36);
                width: spalten($layout, 11, 12, 0, 0);
            }

            &--klein {
                @include abstand($layout, margin, bottom, 5);
                @include schriftart($layout, 18, 24);
            }
        }

        &__details {
            @include abstand($layout, padding, left, 9);
            width: spalten($layout, 11, 12, 0, 0);
            position: relative;

            &::after {
                top: 0.65vw;
                height: calc(100% - 1.6vw);
                width: 1.5px;
            }
        }

        &__details-zeile {
            @include schriftart($layout, 12, 16);

            &--1 {
                @include abstand($layout, padding, bottom, 3);
            }
        }

        &__projekt {

            &--klein {
                width: spalten($layout, 6, 12, 0, 0);
            }
        }

        &__bild {

            &--klein {
                width: 100%;
            }
        }

        &__zitat {
            width: 100%;

            &--1 {
                @include abstand($layout, margin, bottom, 40);
                width: spalten($layout, 11, 12, 0, 0);

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 5);
                    @include schriftart($layout, 32, 36);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 12, 16);
                }
            }

            &--2 {
                @include abstand($layout, margin, bottom, 40);
                position: relative;

                .projekte__zitat-bild {
                    margin-left: spalten($layout, -1, 12, 0, 0);
                    height: 62vh;
                }

                .projekte__zitat-texte {
                    @include abstand($layout, padding, bottom, 15);
                    bottom: 0;
                    color: white;
                    left: 0;
                    position: absolute;
                    width: spalten($layout, 11, 12, 0, 0);
                }

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 5);
                    @include schriftart($layout, 18, 24);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 12, 16);
                }
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .projekte {
        @include abstand($layout, margin, bottom, (96 - 60));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__projekt {
            @include abstand($layout, margin, bottom, 60);
            width: 100%;
        }

        &__introbild {
            @include abstand($layout, margin, bottom, 8);
            margin-left: groesse($layout, -24);
        }

        &__anreisser {

            &--gross {
                @include abstand($layout, margin, bottom, 5);
                @include schriftart($layout, 45, 58);
            }

            &--klein {
                @include abstand($layout, margin, alle, 5, 0, 5, 0);
                @include schriftart($layout, 28, 36);
            }
        }

        &__details {
            @include abstand($layout, padding, left, 11);

            &::after {
                top: 0.6vw;
                height: calc(100% - 1.25vw);
                width: 1.5px;
            }
        }

        &__details-zeile {
            @include schriftart($layout, 14, 21);
        }

        &__projekt {

            &--klein {
                width: spalten($layout, 8, 16, 0, 0);
            }
        }

        &__bild {

            &--klein {
                width: 100%;
            }
        }

        &__zitat {
            width: 100%;

            &--1 {
                @include abstand($layout, margin, bottom, 60);

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 7);
                    @include schriftart($layout, 45, 58);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 14, 21);
                }
            }

            &--2 {
                @include abstand($layout, margin, bottom, 60);
                position: relative;

                .projekte__zitat-bild {
                    margin-left: spalten($layout, -1, 15, 0, 0);
                    height: 80vh;
                }

                .projekte__zitat-texte {
                    @include abstand($layout, padding, bottom, 25);
                    bottom: 0;
                    color: white;
                    left: 0;
                    padding-right: spalten($layout, 1, 16, 0, 0);
                    position: absolute;
                    width: 100%;
                }

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 7);
                    @include schriftart($layout, 36, 45);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 14, 21);
                }
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekte {
        @include abstand($layout, margin, bottom, 75);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__projekt {
            width: 100%;
            @include abstand($layout, margin, bottom, 60);
        }

        &__introbild {
            @include abstand($layout, margin, bottom, 30);
            margin-left: spalten($layout, 1, 19, 0, 1, 0, 20, true);
            overflow: hidden;
        }

        &__anreisser {

            &--gross {
                @include abstand($layout, margin, bottom, 15);
                @include schriftart($layout, 60, 70);
                margin-right: spalten($layout, 1, 19, 0, 1);
            }

            &--klein {
                @include abstand($layout, margin, bottom, 10);
                @include schriftart($layout, 36, 45);
            }
        }

        &__details {
            @include abstand($layout, padding, left, 15);

            &::after {
                top: 0.3vw;
                height: calc(100% - 0.55vw);
                width: 1.5px;
            }
        }

        &__details-zeile {
            @include schriftart($layout, 18, 24);
        }

        &__projekt {

            &--klein {
                width: spalten($layout, 9, 19, 0, 0);
            }

            &:hover {

                .projekte__bild-bild,
                .projekte__introbild-bild {
                    transform: scale(1.1);
                    transition: transform .6s ease-out;
                }

                .projekte__introbild-bild {
                    transform: scale(1.05);
                    transition: transform .6s ease-out;
                }
            }

            .projekte__bild-bild,
            .projekte__introbild-bild {
                transition: transform .6s ease-out;
                transform: scale(1.0);
            }
        }

        &__bild {
            @include abstand($layout, margin, bottom, 20);
            overflow: hidden;

            &--klein {
                width: 100%;
            }
        }

        &__zitat {
            width: 100%;

            &--1 {
                @include abstand($layout, margin, alle, 0, 0, 64, 0);
                width: spalten($layout, 16, 19, 0, 0);

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 25);
                    @include schriftart($layout, 60, 70);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 18, 24);
                }
            }

            &--2 {
                @include abstand($layout, margin, alle, 0, 0, 64, 0);
                position: relative;

                .projekte__zitat-bild {
                    margin-left: spalten($layout, -1, 19, 0, 0);
                    height: 80vh;
                }

                .projekte__zitat-texte {
                    width: spalten($layout, 16, 19, 0, 0);
                    @include abstand($layout, padding, bottom, 60);
                    color: white;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                }

                .projekte__zitat-text {
                    @include abstand($layout, padding, right, 20);
                    @include schriftart($layout, 36, 45);
                }

                .projekte__zitat-quelle {
                    @include schriftart($layout, 18, 21);
                }
            }
        }
    }
}