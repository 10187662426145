.profil {

    &__titel {
        font-family: "SangBleu";
        font-weight: 400;
    }

    &__anreisser {
        font-family: "SangBleu";
        font-weight: 400;
    }

    &__redaktion {
        position: relative;
        
        &::after {
            background-color: #000;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    &__zitat {
        font-family: "SangBleu";
        font-weight: 400;
    }

    &__text {
        font-family: "SangBleu";
        font-weight: 400;
        hyphens: auto;
    }

    &__autor-container {
        font-family: "SangBleu";
    }

    &__autor {
        font-weight: 500;
        position: relative;

        &::after {
            background-color: #000;
            content: "";
            right: 0;
            position: absolute;
        }
    }

    &__rolle {
        font-weight: 400;
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

        .profil {
            @include abstand($layout, margin, alle, 45, 0, 45, 0);

        &__titel {
            @include schriftart($layout, 24, 29);
        }

        &__redaktion-container {
            @include abstand($layout, margin, top, 10);
            @include schriftart($layout, 12, 16);
        }

        &__redaktion {
            @include abstand($layout, padding, left, 9);

            &::after {
                top: 0.6vw;
                height: calc(100% - 1.5vw);
                width: 1.5px;
            }
        }

        &__anreisser {
            @include abstand($layout, margin, alle, 40, 0, 20, 0);
            @include schriftart($layout, 16, 23);
        }

        &__bild {
            @include abstand($layout, margin, alle, 20, 0, 40, 0);
            margin-left: spalten($layout, -1, 10, 0, 1);
        }
        
        &__bildmitzitat {
            @include abstand($layout, margin, alle, 20, 0, 20, 0);
            margin-left: spalten($layout, -1, 10, 0, 1);

            &--hoch {
                display: flex;
                justify-content: space-between;
            }

            &--quer {
            }
        }

        &__spalte-small {

            &--1 {
                width: spalten($layout, 4, 11, 0, 0);   
            }
            
            &--2 {
                width: spalten($layout, 7, 11, 0, 0);   
            }
        }

        &__zitat-container {

            &--quer {
                @include abstand($layout, margin, alle, 20, 0, 20, 0);
            }
        }

        &__zitat {
            @include schriftart($layout, 16, 23);
        }

        &__autor-container {
            @include abstand($layout, margin, top, 3);
            @include schriftart($layout, 12, 16);
            display: flex;
            flex-wrap: wrap;
        }

        &__autor {
            @include abstand($layout, padding, right, (5 + 2));
            @include abstand($layout, margin, right, (5 + 2));

            &::after {
                top: 0.2vw;
                height: calc(100% - 0.22vw);
                width: 1.5px;
            }
        }

        &__text {
            @include abstand($layout, margin, alle, 20, 0, 0, 0);
            @include schriftart($layout, 12, 16);

            .profil__spalte {

                &--1 {
                    @include abstand($layout, margin, bottom, 10);
                }
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .profil {

        @include abstand($layout, margin, alle, 94, 0, 94, 0);

        &__titel {
            @include schriftart($layout, 48, 56);
        }

        &__redaktion-container {
            @include abstand($layout, margin, top, 8);
            @include schriftart($layout, 14, 21);
            width: spalten($layout, 10, 14, 0, 0);
        }

        &__redaktion {
            @include abstand($layout, padding, left, 11);

            &::after {
                top: 0.6vw;
                height: calc(100% - 1.5vw);
                width: 1.5px;
            }
        }

        &__anreisser {
            @include abstand($layout, margin, alle, 60, 0, 32, 0);
            @include schriftart($layout, 21, 29);
        }

        &__bild {
            @include abstand($layout, margin, alle, 32, 0, 60, 0);
        }

        &__bildmitzitat {
            @include abstand($layout, margin, alle, 32, 0, 32, 0);

            &--hoch {
                display: flex;
                justify-content: space-between;
            }

            &--quer {
                width: spalten($layout, 11, 14);
            }
        }

        &__spalte-small {
            width: spalten($layout, 7, 14, 0, 0);
        }

        &__zitat-container {

            &--quer {
                @include abstand($layout, margin, alle, 32, 0, 32, 0);
            }
        }

        &__zitat {
            @include schriftart($layout, 21, 29);
        }

        &__autor-container {
            @include abstand($layout, margin, top, 3);
            @include schriftart($layout, 12, 16);
            display: flex;
            flex-wrap: wrap;
        }

        &__autor {
            @include abstand($layout, padding, right, (5 + 2));
            @include abstand($layout, margin, right, (5 + 2));

            &::after {
                top: 0.2vw;
                height: calc(100% - 0.22vw);
                width: 1.5px;
            }
        }

        &__text {
            @include abstand($layout, margin, alle, 32, 0, 60, 0);
            @include schriftart($layout, 14, 21);
            margin-left: spalten($layout, 1, 14, 0, 1);

            .profil__spalte {

                &--1 {
                    @include abstand($layout, margin, bottom, 16);
                }
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .profil {
        @include abstand($layout, margin, alle, 195, 0, 195, 0);

        &__intro-container {
            display: flex;
        }

        &__titel {
            @include schriftart($layout, 60, 70);
            width: spalten($layout, 12, 17, 0, 0);
            margin-right: spalten($layout, 0, 17, 0, 2);
        }

        &__redaktion-container {
            @include schriftart($layout, 18, 24);
            @include abstand($layout, margin, top, 15);
            width: spalten($layout, 5, 17, 0, 0);
        }

        &__redaktion {
            @include abstand($layout, padding, left, 15);

            &::after {
                top: 0.3vw;
                height: calc(100% - 0.55vw);
                width: 1.5px;
            }
        }

        &__anreisser {
            @include abstand($layout, margin, alle, 72, 0, 36, 0);
            @include schriftart($layout, 36, 45);
        }

        &__bild {
            @include abstand($layout, margin, alle, 36, 0, 72, 0);
        }

        &__bildmitzitat {
            @include abstand($layout, margin, alle, 36, 0, 36, 0);

            &--hoch {
                display: flex;
                justify-content: space-between;
            }

            &--quer {
                width: spalten($layout, 13, 18, 0, -1);
            }
        }

        &__zitat-container {

            &--quer {
                @include abstand($layout, margin, alle, 36, 0, 36, 0);
            }
        }

        &__zitat {
            @include schriftart($layout, 36, 45);
        }

        &__autor-container {
            @include abstand($layout, margin, top, 6);
            @include schriftart($layout, 12, 16);
            display: flex;
            flex-wrap: wrap;
        }

        &__autor {
            @include abstand($layout, padding, right, (5 + 2));
            @include abstand($layout, margin, right, (5 + 2));

            &::after {
                top: -0.03vw;
                height: calc(100% + 0.1vw);
                width: 1.5px;
            }
        }

        &__text {
            @include abstand($layout, margin, alle, 36, 0, 72, 0);
            @include schriftart($layout, 18, 24);
            display: flex;
            justify-content: space-between;
        }

        &__spalte {
            width: spalten($layout, 8, 17, 0, 0);

        }
        
        &__spalte-small {
            &--2 {
                margin-top: -0.55vw;
            }
        }
    }
}