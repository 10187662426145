// Alle Interaktionen der inaktiven Bühne sollen deaktiviert sein. Sonst ist es beispielsweise möglich, bei geöffneter Detailansicht eines Projekts auf der links in den Viewport hineinragenden Bühne ein anderes Projekt anzuklicken.
// .body[data-buehne-aktiv="2"] {

//     .buehnen__buehne--1 {
//         pointer-events: none
//     }
// }

.buehnen {
    display: flex;
    position: relative;
    justify-content: flex-start;

    .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
        display: flex;
        justify-content: flex-end;
    }

    &__buehne {
        width: 100%;

        &--1 {
            position: relative;
            transform: translateX(0%);
            width: 100%;

            .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
                left: 0;
                position: fixed;
            }
        }
        
        &--2 {
            position: fixed;
            right: 0;
            transform: translateX(100%);
            transition: transform 1s;
            top: 0;
            
            .body[data-buehne-aktiv="1"] & {
                pointer-events: none
            }

            .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
                position: relative;
            }
        }
    }

    
    .buehnen__link-buehne-1 {
        height: 100%;
        display: none;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 100;
        
        .body[data-buehne-aktiv="2"] & {
            display: block;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .buehnen {

        &__buehne {
            
            &--1 {
                @include abstand($layout, padding, alle, 0, 37, 0, 12);
                transition: transform 1s;
                
                .body[data-buehne-aktiv="2"] & {
                    transition: transform 1s;
                    transform: translateX(spalten($layout, 10, 12, 0, 0, 12 + 37, 37, true));
                }
            }
            
            &--2 {
                @include abstand($layout, padding, alle, 0, 37, 0, 0);
                width: spalten($layout, 10, 12, 0, 0, 12 + 37, 37);

                .body[data-buehne-aktiv="2"] & {
                    transition: transform 1s;
                    transform: translateX(0%);
                }
            }
        }
    }

    .body[data-buehne-aktiv="2"] .dev-toolbar__raster {
        // scale: -1;
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .buehnen {

        &__buehne {
            
            &--1 {
                @include abstand($layout, padding, alle, 0, 80, 0, 24);
                transition: transform 1s;
                
                .body[data-buehne-aktiv="2"] & {
                    transform: translateX(spalten($layout, 13, 16, 0, 0.5, 80 + 24, 80, true));
                    transition: transform 1s;
                }
            }
            
            &--2 {
                @include abstand($layout, padding, alle, 0, 80, 0, 0);
                width: spalten($layout, 14, 16, 0, 0, 80 + 24, 80);

                .body[data-buehne-aktiv="2"] & {
                    transition: transform 1s;
                    transform: translateX(0%);
                }
            }
        }
    }

    .body[data-buehne-aktiv="2"] .dev-toolbar__raster {
        // scale: -1;
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .buehnen {

        &__buehne {
            
            .body[data-buehnen-animiert="true"] & {
                transition: transform 1s;
            }
            .body[data-buehnen-animiert="false"] & {
                transition: transform 0.2s;
            }
            
            &--1 {
                @include abstand($layout, padding, alle, 0, spalten($layout, 4, 24, 0, 1, 20 + 20, 20), 0, spalten($layout, 1, 24, 0, 1, 20 + 20, 20));

                .body[data-buehne-aktiv="2"] & {
                    transform: translateX(spalten($layout, 18, 24, 0, 0, 20 + 20, 20, true));
                }
            }

            &--2 {
                padding-right: spalten($layout, 1, 18, 0, 1, 20, 0);
                width: spalten($layout, 18, 24, 0, 0, 20 + 20, 20);

                .body[data-buehne-aktiv="2"] & {
                    transform: translateX(0);
                }
            }
        }
    }

    .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"][data-buehne-hover="1"] .menue,
    .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"][data-buehne-hover="1"] .titel__text,
    .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"][data-buehne-hover="1"] .buehnen__buehne {
        animation: buehneAnziehen 0.05s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }
    @keyframes buehneAnziehen {
        0% { translate: 0 0; }
        100% { translate: (groesse($layout, 10)) 0; }
    }
    
    .body[data-buehne-aktiv="2"][data-buehne-hover="false"] .menue,
    .body[data-buehne-aktiv="2"][data-buehne-hover="false"] .titel__text,
    .body[data-buehne-aktiv="2"][data-buehne-hover="false"] .buehnen__buehne {
        animation: buehneLoslassen 0.25s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
    }
    @keyframes buehneLoslassen {
        0% { translate: (groesse($layout, 10)) 0; }
        100% { translate: 0 0; }
    }
}