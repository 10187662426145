.cursor {
    display: none;
    top: calc(var(--mouse-y) * 100vh - 8px);
    left: calc(var(--mouse-x) * 100vw - 8px);
    position: fixed;
    z-index: 99999999;
    pointer-events: none;
    transition:
        top 0.2s ease-out,
        left 0.2s ease-out,
        width 0.5s,
        height 0.5s,
        transform 0.5s,
        border-radius 0.5s;
    transform: translate(-2px, -2px);

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50%;

    body[data-cursor-touch="cursor"] & {
        display: block;
    }

    body[data-linkhover="true"][data-cursor="pfeilkreis"][data-cursor-touch="cursor"][data-buehne-aktiv="1"] & {
        background-image: url("/bausteine/cursor/cursor.svg");
        transform: translate(-1.806vw, -1.806vw);
    }

    body[data-linkhover="true"][data-cursor="pfeilkreis"][data-cursor-touch="cursor"][data-buehne-aktiv="2"] & {
        background-image: url("/bausteine/cursor/cursor.svg");
        transform: translate(-1.806vw, -1.806vw);
    }

    body[data-linkhover="true"][data-cursor="atkreis"][data-cursor-touch="cursor"] & {
        background-image: url("/bausteine/cursor/at.svg");
        transform: translate(-1.806vw, -1.806vw);
    }

    body[data-linkhover="true"][data-cursor="telkreis"][data-cursor-touch="cursor"] & {
        transform: translate(-1.806vw, -1.806vw);
        border-color: transparent;
        transition: border-color 0 linear 0.5s;
        
        &::after {
            background-image: url("/bausteine/cursor/cursor__sprechblase.svg");
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
    }

    body[data-linkhover="true"][data-cursor="pfeilkreis"][data-cursor-touch="cursor"][data-buehne-aktiv="2"][data-buehne-hover="1"] & {
        background-image: url("/bausteine/cursor/cursor__back.svg");
        transform: translate(-1.806vw, -1.806vw);
    }
}

body[data-linkhover="true"][data-cursor-touch="cursor"] {
    &[data-cursor="pfeilkreis"] a,
    &[data-cursor="atkreis"] a,
    &[data-cursor="telkreis"] a {
        cursor: none;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .cursor {
        border: groesse($layout, 2) solid black;
        border-radius: groesse($layout, 4);
        height: groesse($layout, 8);
        width: groesse($layout, 8);
    }

    body[data-linkhover="true"][data-cursor-touch="cursor"] {
        &[data-cursor="pfeilkreis"] .cursor {
            border-radius: groesse($layout, 12.5);
            width: groesse($layout, 25);
            height: groesse($layout, 25);
        }
        
        &[data-cursor="atkreis"] .cursor {
            border-radius: groesse($layout, 12.5);
            width: groesse($layout, 25);
            height: groesse($layout, 25);
        }

        &[data-cursor="telkreis"] .cursor {
            border-radius: groesse($layout, 12.5);
            width: groesse($layout, 25);
            height: groesse($layout, 25);
            
            &::after {
                content: "";
                height: 5vw;
                left: groesse($layout, -2);
                position: absolute;
                top: groesse($layout, -2);
                width: calc(100% + groesse($layout, 4));
            }
        }
    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .cursor {
        border: groesse($layout, 2) solid black;
        border-radius: groesse($layout, 4);
        width: groesse($layout, 8);
        height: groesse($layout, 8);
    }

    body[data-linkhover="true"][data-cursor-touch="cursor"] {
        &[data-cursor="atkreis"] .cursor {
            border-radius: groesse($layout, 17.5);
            width: groesse($layout, 35);
            height: groesse($layout, 35);
        }

        &[data-cursor="pfeilkreis"] .cursor {
            border-radius: groesse($layout, 17.5);
            width: groesse($layout, 35);
            height: groesse($layout, 35);
        }
        
        &[data-cursor="telkreis"] .cursor {
            border-radius: groesse($layout, 17.5);
            width: groesse($layout, 35);
            height: groesse($layout, 35);
            
            &::after {
                content: "";
                height: 5vw;
                left: groesse($layout, -2);
                position: absolute;
                top: groesse($layout, -2);
                width: calc(100% + groesse($layout, 4));
            }
        }
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .cursor {
        border: groesse($layout, 2) solid black;
        border-radius: groesse($layout, 6);
        height: groesse($layout, 12);
        width: groesse($layout, 12);
    }
    
    body[data-linkhover="true"][data-cursor-touch="cursor"] {
        &[data-cursor="pfeilkreis"] .cursor {
            border-radius: groesse($layout, 22);
            height: groesse($layout, 44);
            width: groesse($layout, 44);
        }
        
        &[data-cursor="atkreis"] .cursor {
            border-radius: groesse($layout, 22);
            height: groesse($layout, 44);
            width: groesse($layout, 44);
        }
        
        &[data-cursor="telkreis"] .cursor {
            border-radius: groesse($layout, 22);
            height: groesse($layout, 22);
            width: groesse($layout, 44);
            
            &::after {
                content: "";
                height: 5vw;
                left: groesse($layout, -2);
                position: absolute;
                top: groesse($layout, -2);
                width: calc(100% + groesse($layout, 4));
            }
        }
    }
}