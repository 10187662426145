.werkliste {
    &__projekt {
        position: relative;
        display: flex;
        flex-direction: column;
        color: black;
        text-decoration: none;

        &[href] {
            .werkliste__titel {
                font-style: italic;
            }
        }
    }

    &__titel {
        font-family: "SangBleu";
        font-weight: 400;
    }

    &__gruppe {
        font-family: "SangBleu";
        font-weight: 500;
    }

    &__details {
        font-family: "SangBleu";
        font-weight: 400;
        position: relative;
        height: 100%;
    }

    &__inhalt {
        position: relative;

        &::after {
            background-color: #000;
            content: "";
            left: 0;
            position: absolute;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .werkliste {
        // - 20 projekt
        @include abstand($layout, margin, alle, 55, 0, (80 - 20), 0);

        &__gruppe {
            @include schriftart($layout, 12, 12);
            @include abstand($layout, margin, alle, 40, 0, 4, 0);
        }

        &__projekt {
            &--ohne-link {
                @include abstand($layout, margin, bottom, 20)
            }
            
            &--mit-link {
                @include abstand($layout, margin, bottom, 40)
            }
        }

        &__titel {
            @include schriftart($layout, 26, 32);
            @include abstand($layout, margin, bottom, 10);
        }

        &__details {
            @include schriftart($layout, 12, 16);
            display: flex;
            height: 100%;
            justify-content: space-between;
        }

        &__inhalt {
            padding-left: groesse($layout, 7);
            width: spalten($layout, 6 + 0.45, 10, 0, 1);

            &::after {
                top: 0.7vw;
                height: calc(100% - 1.7vw);
                width: 1.5px;
            }
        }

        &__bild {
            width: spalten($layout, 3 + calc(1/6), 10, 0, 1);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .werkliste {
        // - 16 projekt
        @include abstand($layout, margin, alle, 96, 0, (96 - 16), 0);

        &__gruppe {
            @include schriftart($layout, 14, 14);
            @include abstand($layout, margin, alle, 72, 0, 5, 0);
        }

        &__projekt {
            &--ohne-link {
                @include abstand($layout, margin, bottom, 16)
            }
            
            &--mit-link {
                @include abstand($layout, margin, bottom, 72)
            }
        }

        &__titel {
            @include schriftart($layout, 48, 56);
            @include abstand($layout, margin, bottom, 5);
        }

        &__details {
            @include schriftart($layout, 14, 21);
            display: flex;
            height: 100%;
            justify-content: space-between;
        }

        &__inhalt {
            padding-left: spalten($layout, 1.5, 28, 0, 0);
            width: spalten($layout, 5, 14, 0, 0);

            &::after {
                top: 0.535vw;
                height: calc(100% - 1.2vw);
                width: 1.5px;
            }
        }

        &__bild {
            width: spalten($layout, 5, 14, 0, 0);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .werkliste {
        // 199 .werkliste__details
        @include abstand($layout, margin, alle, 195, 0, (75 + 199), 0);

        &__gruppe {
            @include schriftart($layout, 18, 18);
            @include abstand($layout, margin, alle, 72, 0, 5, 0);
        }

        &__projekt {
            padding-bottom: groesse($layout, 60);
            padding-right: spalten($layout, 4, 17, 0, 1);

            &:hover {
                .werkliste__details {
                    opacity: 1;
                    transition: 0.3s;
                    transition-timing-function: ease-in;
                }
            }
        }

        &__titel {
            @include schriftart($layout, 60, 70);
        }

        &__details {
            margin-right: spalten($layout, -1, 17, 0, 1);
            @include schriftart($layout, 18, 24);
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: 0.2s;
            transition-timing-function: ease-out;
            width: spalten($layout, 5, 17, 0, 0);
        }

        &__inhalt {
            @include abstand($layout, padding, left, 15);

            &::after {
                top: 0.3vw;
                height: calc(100% - 0.55vw);
                width: 1.5px;
            }
        }

        &__bild {
            width: 100%;
            margin-top: groesse($layout, 13);
        }
    }
}