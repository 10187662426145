@font-face {
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-Bold-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-Bold-WebS.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-BoldItalic-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-BoldItalic-WebS.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face{
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-Medium-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-Medium-WebS.woff") format("woff");
    font-weight: 500;
}

@font-face{
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-MediumItalic-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-MediumItalic-WebS.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face{
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-Regular-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-Regular-WebS.woff") format("woff");
    font-weight: 400;
}

@font-face{
    font-family: "SangBleu";
    src: url("/fonts/SangBleuRepublic-RegularItalic-WebS.woff2") format("woff2"),
    url("/fonts/SangBleuRepublic-RegularItalic-WebS.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}