.logo {
    color: white;
    font-family: "SangBleu";
    font-weight: 500;
    display: flex;
    mix-blend-mode: exclusion;
    z-index: 10;
    
    &__text {
        width: 100%;
        
        &--1 {
            left: 0;
            position: fixed;
            text-align: end;
            transform: translateX(0);
            transition: transform 1.0s;
            top: 0;
        }
        &--2 {
            left: 0;
            position: fixed;
            transform: translateX(0);
            transition: transform 1.0s;
            top: 0;
            
            .body[data-buehne-aktiv="2"][data-buehnen-animiert="false"] & {
                position: absolute;
            }
            
            .body[data-buehne-aktiv="1"][data-buehnen-animiert="true"] & {
                animation: logo 0.5s;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in;
            }
            @keyframes logo {
                0% { opacity: 0; }
                100% { opacity: 1; }
            }
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .logo {
        position: absolute;
        width: 100%;

        .body[data-menue-aktiv="true"] &,
        .body[data-menue-bewegt="true"] & {
            display: none;
        }
        
        &__text {
            @include schriftart($layout, 18, 27);
            word-spacing: groesse($layout, -1);
            
            &--1 {
                padding-right: groesse($layout, 37);
            }
            
            &--2 {
                padding-left: calc(100% - groesse($layout, 37));
            }

            &--1,
            &--2 {
                .body[data-buehne-aktiv="2"] & {
                    // Kopiert von _buehnen.scss
                    transform: translateX(spalten($layout, 10, 12, 0, 0, 12 + 37, 37, true));
                }
            }
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .logo {
        position: absolute;
        width: 100%;

        .body[data-menue-aktiv="true"] &,
        .body[data-menue-bewegt="true"] & {
            display: none;
        }
        
        &__text {
            @include schriftart($layout, 28, 41);
            word-spacing: groesse($layout, -3);
            
            &--1 {
                padding-right: spalten($layout, 1, 16, 4, 2.5);

            }
            
            &--2 {
                padding-left: spalten($layout, 15, 16, 4, 2.5);
            }

            &--1,
            &--2 {
                .body[data-buehne-aktiv="2"] & {
                    // Kopiert von _buehnen.scss
                    transform: translateX(spalten($layout, 13, 16, 0, 0.5, 80 + 24, 80, true));
                }
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .logo {
        position: absolute;
        width: 100%;
        
        
        &__text {
            @include schriftart($layout, 36, 53);
            word-spacing: groesse($layout, -3);
            
            &--1 {
                padding-right: spalten($layout, 4, 24, 2, 2);
            }
            
            &--2 {
                padding-left: spalten($layout, 20, 24, 2, 1);    
            }
            
            &--1,
            &--2 {
                .body[data-buehne-aktiv="2"] & {
                    // kopiert von _buehnen.scss
                    transform: translateX(spalten($layout, 18, 24, 0, 0, 20 + 20, 20, true));
                }
            }
        }
    }
}