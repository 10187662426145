.body--404 {
    background-color: black;

    .menue {
        color: white;
        
        &__link  {
            color: white;
        }
        
        &__link-sprache {
            color: white;
        }
    }
    
    .menue__hamburger .hamburger-inner, .menue__hamburger .hamburger-inner::after, .menue__hamburger .hamburger-inner::before {
        background-color: white;
    }
    
    .cursor {
        border: 2px solid #fff;
    }
}


.error {
    color: white;
    font-family: "SangBleu";
    font-weight: 500;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .error {
        @include abstand($layout, margin, alle, 55, 0, 0, 0);
        margin-left: spalten($layout, 1, 12, 1, 1);
        margin-right: spalten($layout, 0, 12, 0, 2.2);

        &__text {
            @include abstand($layout, margin, bottom, 40);
            @include schriftart($layout, 26, 32);
        }
    }

}

@include mediaquery(tablet) {

    $layout: tablet;

    .error {
        @include abstand($layout, margin, alle, 96, 0, 0, 0);
        
        &__text {
            @include abstand($layout, margin, bottom, 64);
            @include schriftart($layout, 48, 56);
            padding-right: groesse($layout, 80);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .error {
        @include abstand($layout, margin, alle, 195, 0, -72, 0);
        padding-right: spalten($layout, 1, 18, 0, 2);

        &__text {
            @include schriftart($layout, 60, 70);
            @include abstand($layout, margin, bottom, 72);
        }
    }
}