.teammitglied {

    font-family: "SangBleu";

    &__name {
        font-weight: 700;
    }
    &__details {
        font-weight: 400;
    }

}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .teammitglied {
        @include abstand($layout, margin, bottom, 20);
        @include schriftart($layout, 12, 16);

        &__bild {
            @include abstand($layout, margin, bottom, 2);
            margin-right: spalten($layout, 0, 11, 0, 2);
        }
    }
}

@include mediaquery(tablet) {

    $layout: tablet;

    .teammitglied {
        @include abstand($layout, margin, bottom, 32);
        @include schriftart($layout, 14, 21);
        width: spalten($layout, 7, 14, 1.5, 0);
        margin-left: spalten($layout, 0, 14, 0, 1.5);
        margin-right: spalten($layout, 0, 14, 0, 1.5);
        
        &__bild {
            @include abstand($layout, margin, bottom, 3);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .teammitglied {
        @include abstand($layout, margin, bottom, 72);
        width: spalten($layout, 5, 18, 1, 0);
        margin-left: spalten($layout, 0.5, 18, 1, 1.5);
        margin-right: spalten($layout, 0.5, 18, 1, 1.5);
        
        &__name {
            @include abstand($layout, margin, bottom, 5);
            @include schriftart($layout, 18, 24);
        }
        
        &__details {
            @include schriftart($layout, 14, 21);
        }

        &__bild {
            @include abstand($layout, margin, bottom, 5);
        }
    }

}