.projekt {

    &__anreisser {
        color: black;
        font-family: "SangBleu";
        font-weight: 400;
        
        // Auch wenn kein Button im Redactor field für bold steht,
        // kann man per Shortcut bold setzen.
        // Zusätzlich Shortcuts im Redactor field deaktiviert.
        & strong {
            font-weight: 400;
        }

        & em {
            font-family: "SangBleu";
            font-style: italic;
        }
    }

    &__details {
        // Damit border-left nur so gross ist wie __details und nicht so gross wie die Flexbox
        font-family: "SangBleu";
        font-weight: 400;
        height: 100%;
        position: relative;

        &::after {
            background-color: #000;
            content: "";
            left: 0;
            position: absolute;
        }
    }

    &__details-titel {
        font-family: "SangBleu";
        font-weight: 700;
    }

    &__beschreibung {
        font-family: "SangBleu";
        font-weight: 400;

        & em {
            font-family: "SangBleu";
            font-style: italic;
        }

        & h3 {
            font-family: "SangBleu";
            font-weight: 700;
        }

        & h2 {
            font-family: "SangBleu";
            font-weight: 400;
        }

        & p {
            font-family: "SangBleu";
            font-weight: 400;
        }
    }

    &__beschreibung-zitat {
        font-family: "SangBleu";
        font-weight: 400;
        margin: 0;
    }

    &__projektbild-text {
        font-family: "SangBleu";
        font-weight: 500;
        text-align: center;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt {
        @include abstand($layout, margin, top, 64);

        &__anreisser {
            @include schriftart($layout, 26, 32);
        }

        &__details {
            @include abstand($layout, margin, top, 10);
            @include abstand($layout, padding, left, 9);
            width: spalten($layout, 9, 10, 0, 0);

            &::after {
                top: 0.74vw;
                height: calc(100% - 2.1vw);
                width: 1.5px;
            }
        }

        &__details-titel {
            @include schriftart($layout, 12, 16);
        }

        &__details-inhalt {
            @include schriftart($layout, 12, 16);
        }

        &__introbild {
            @include abstand($layout, margin, alle, 40, 0, 20, 0);
            margin-left: spalten($layout, 0, 12, 0, 0);
        }

        &__beschreibung {

            &>*:first-child {
                margin-top: 0;
            }

            & h2 {
                @include abstand($layout, margin, alle, 10, 0, 10, 0);
                @include schriftart($layout, 18, 24);
            }

            & h3 {
                @include schriftart($layout, 12, 16);
            }

            & p {
                @include schriftart($layout, 12, 16);
                @include abstand($layout, margin, alle, 10, 0, 10, 0);

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }

        & .projekt__beschreibung-zitat {
            @include abstand($layout, margin, alle, 5, 0, 5, 0);
            @include schriftart($layout, 18, 24);
            margin-left: spalten($layout, -1, 14, 0, 0.4);
        }

        &__projektbilder {
            // - 10 projektbild
            @include abstand($layout, margin, alle, (40 - 10), 0, (80 - 10), 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__projektbilder-kompakt {
            @include abstand($layout, margin, alle, (40 - 10), 0, (80 - 10), 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__projektbild {
            @include abstand($layout, padding, bottom, 10);
            width: 100%;
            margin: 0;

            &--klein {
                width: spalten($layout, 5, 10, 0, 0);
            }
        }

        &__projektbild-text {
            @include abstand($layout, margin, top, 3);
            @include schriftart($layout, 12, 16);
        }
    }
}


@include mediaquery(tablet) {

    $layout: tablet;

    .projekt {
        @include abstand($layout, margin, top, 94);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__anreisser {
            @include schriftart($layout, 48, 56);
            width: spalten($layout, 8, 14);
        }

        &__details {
            @include abstand($layout, padding, left, 11);
            width: spalten($layout, 6, 14);

            &::after {
                top: 0.5vw;
                height: calc(100% - 1.4vw);
                width: 1.5px;
            }
        }

        &__details-titel {
            @include schriftart($layout, 14, 21);
        }

        &__details-inhalt {
            @include schriftart($layout, 14, 21);
        }

        &__introbild {
            @include abstand($layout, margin, alle, 32, 0, 32, 0);
            width: 100%;
        }

        &__beschreibung {
            margin-left: spalten($layout, 1, 14, 0, 1);

            &>*:first-child {
                margin-top: 0;
            }

            & h2 {
                @include abstand($layout, margin, alle, 16, 0, 16, 0);
                @include schriftart($layout, 28, 36);
            }

            & h3 {
                @include schriftart($layout, 14, 21);
            }

            & p {
                @include schriftart($layout, 14, 21);
                @include abstand($layout, margin, alle, 16, 0, 16, 0);

                &:first-of-type {
                    margin: 0;
                }

            }

            & .projekt__beschreibung-zitat {
                @include abstand($layout, margin, alle, 8, 0, 8, 0);
                @include schriftart($layout, 28, 36);
                margin-left: spalten($layout, -1, 14, 0, 1);
                margin-right: spalten($layout, 2, 14, 0, 1);
            }
        }

        &__projektbilder, &__projektbilder-kompakt {
            // - 32 projektbild
            @include abstand($layout, margin, alle, (64 - 16), 0, (96 - 32), 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        &__projektbild {
            @include abstand($layout, padding, bottom, 32);
            width: 100%;
            margin: 0;

            &--klein {
                // width: spalten($layout, 7, 14, 0, 0.5, 0, -2.5);
                width: spalten($layout, 7, 14);
            }
        }

        &__projektbild-text {
            @include abstand($layout, margin, top, 4.6);
            @include schriftart($layout, 12, 16);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekt {
        @include abstand($layout, margin, top, 195);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__anreisser {
            @include schriftart($layout, 60, 70);
            width: spalten($layout, 12, 17, 0, 0);
        }

        &__details {
            @include abstand($layout, margin, top, 14);
            @include abstand($layout, padding, left, 15);
            width: spalten($layout, 5, 17, 0, 0);

            &::after {
                top: 0.3vw;
                height: calc(100% - 0.55vw);
                width: 1.5px;
            }
        }

        &__details-titel {
            @include schriftart($layout, 18, 24);
        }

        &__details-inhalt {
            @include schriftart($layout, 18, 24);
        }

        &__introbild {
            @include abstand($layout, margin, alle, 36, 0, 36, 0);
            width: 100%;
        }

        &__beschreibung {
            @include abstand($layout, margin, alle, 20, 0, 0, 0);
            margin-left: spalten($layout, 2, 17, 0, 1);
            margin-right: spalten($layout, 2, 17, 0, 1);

            &>*:first-child {
                margin-top: 0;
            }

            & h2 {
                @include abstand($layout, margin, alle, 18, 0, 18, 0);
                @include schriftart($layout, 36, 45);
            }

            & h3 {
                @include schriftart($layout, 18, 24);
            }

            & p {
                @include schriftart($layout, 18, 24);
                @include abstand($layout, margin, alle, 18, 0, 18, 0);

                &:first-of-type {
                    @include abstand($layout, margin, alle, 5, 0, 18, 0);
                }
            }

            & .projekt__beschreibung-zitat {
                @include schriftart($layout, 36, 45);
            }
        }

        &__beschreibung-zitat {
            @include abstand($layout, margin, alle, 35, 0, 35, 0);
            margin-left: spalten($layout, -2, 13, 0, 1);
        }

        &__projektbilder, &__projektbilder-kompakt {
            // - 72 projektbild
            @include abstand($layout, margin, alle, (72 - 18), 0, (108 - 72), 0);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        &__projektbild {
            // padding bottom, da figure auto margin hat
            @include abstand($layout, padding, bottom, 72);
            width: 100%;
            margin: 0;

            &--klein {
                width: spalten($layout, 8, 17, 0, 0);
            }
        }

        &__projektbild-text {
            @include abstand($layout, margin, top, 5.8);
            @include schriftart($layout, 12, 16);
        }
    }
}